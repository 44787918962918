import physician_details from '../../../../../assets/pictures/icons/phydetail1.png'
import physician_documents from '../../../../../assets/pictures/icons/docu1.png'
import physician_consultaion from '../../../../../assets/pictures/icons/consultation1.png'
import React, { useEffect, useState } from 'react'
import PhysicianDetails from './components/Tabpanes/PhysicianDetails'
import { Nav, Tab, TabPane } from 'react-bootstrap'
import './Registration.css'
import PhysicianDocuments from './components/Tabpanes/PhysicianDocuments'
import usePhysicianServices from '../../../../services/api-services/physicianServices'
import { useNavigate } from 'react-router-dom'
import useGetListDatas from '../../../../services/other-services/getListDatas'
import Swal from 'sweetalert2'
import moment from 'moment'
import PhysicianShift from './components/Tabpanes/PhysicianShift'
import useAccountServices from '../../../../services/api-services/accountServices'
import { useLocation } from 'react-router-dom'

const Registration = () => {

    const location = useLocation()
    
    const { PhysicianEditData } = location.state || {}; // Destructure the state

    useEffect(() => {
        if (PhysicianEditData) {
            handlePhysicianSearch(PhysicianEditData);
        }
    }, [PhysicianEditData]);
    //<<<<<<<<<<<<<<<<<<<<<<<<<<< State Declarations >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>    
    // Physician Basic Detail Values
    const [physicianId, setPhysicianId] = useState('')
    const [joinedDate, setJoinedDate] = useState('')
    const [title, setTitle] = useState('')
    const [physicianName, setPhysicianName] = useState('')
    const [licenseNum, setLicenseNum] = useState('')
    const [speciality, setSpeciality] = useState('')
    const [licenseExpDate, setLicenseExpDate] = useState('')
    const [image, setImage] = useState(undefined)
    const [email, setEmail] = useState('')
    const [mobileNumber, setMobileNumber] = useState('')
    const [dOB, setDOB] = useState('')
    const [gender, setGender] = useState('')
    const [city, setCity] = useState('')
    const [pincode, setPincode] = useState('')
    const [district, setDistrict] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [qualifications, setQualifications] = useState([])

    // Physician Document Upload Values

    const [documentName, setDocumentName] = useState("")
    const [documentType, setDocumentType] = useState("")
    const [documentFile, setDocumentFile] = useState(undefined)
    const [documentList, setDocumentList] = useState(undefined)

    // Physician Shift Upload Values

    const [singleSelect, setSingleSelect] = useState(true)
    const [selectedDates, setSelectedDates] = useState(['mon'])
    const [fromTime, setFromTime] = useState('')
    const [totime, setToTime] = useState('')
    const [consultFee, setConsultFee] = useState('')
    const [consultType, setConsultType] = useState(true)
    const [consultationDetails, setConsultationDetails] = useState('')
    const [shiftList, setShiftList] = useState([])

    // Other Values
    const [key, setKey] = useState("tab1")
    const [basicId, setBasicId] = useState("")
    const [titleList, setTitleList] = useState("")
    const [specialityList, setSpecialityList] = useState("")
    const [documentTypeList, setDocumentTypeList] = useState("")

    const navigate = useNavigate()

    //<<<<<<<<<<<<<<<<<<<<<<<<<<< Service Declarations >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // GET APIS
    const {
        getPhysicianInfoChoices,
        getPhysicianSpecialities,
        getPhysicianDocumentTypes,
        getPhysicianQualifications,
        getPhysicianDocuments,
        getPhysicianShifts,
        getPhysicianConsultationDetails
    } = usePhysicianServices()

    const {
        getNewID
    } = useAccountServices()

    // POST APIS
    const {
        postPhysicianSpeciality,
        postPhysicianDocumentType,
        postPhysicianRegister,
        postPhysicianQualification,
        postPhysicianDocuments,
        postPhysicianShifts,
        postPhysicianConsultationDetails
    } = usePhysicianServices()

    // Delete APIS
    const {
        deletePhysicianQualification,
        deletePhysicianDocuments,
        deletePhysicianShifts
    } = usePhysicianServices()

    // External Functions
    const {
        getDropdownList,
        getMultiInputList,
        getSemanticOptionsList
    } = useGetListDatas()

    //<<<<<<<<<<<<<<<<<<<<<<<<<<< Function Declarations >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    // GET Functions

    const getPhysicianId = async () => {
        try {
            const response = await getNewID('physician_id')
            if (response.success) {
                setPhysicianId(response?.data?.complete_id)
            }
        } catch { }
    }

    const getPhysicianDocumentList = async () => {
        try {
            const response = await getPhysicianDocuments(basicId)
            if (response.success) {
                setDocumentList(response?.data)
            }
        } catch { }
    }

    const getPhysicianShiftList = async () => {
        try {
            if (selectedDates){
                const data = {}
                selectedDates.map((item, index)=>data['day'+index]=item)
                const response = await getPhysicianShifts(basicId, data)
                if (response.success) {
                    setShiftList(response?.data)
                }
                else{
                    setShiftList([])
                }
            }
        } catch(err){ 
            setShiftList([])
        }
    }

    const getConsultationDetails = async () => {
        try{
            const response = await getPhysicianConsultationDetails(basicId)
            if(response?.success){
                setConsultationDetails(response?.data)
                setConsultFee(response?.data?.offline_fee)
            }
            else{
                setConsultationDetails('')
            }
        } catch(err){ 
            setConsultationDetails('')
        }
    }

    // get dropdown datas

    const physicianInfoChoice = async () => {
        try {
            const response = await getPhysicianInfoChoices()
            if (response.success) {
                const data = response.data.titles
                var tempList = []
                data.map((item) => {
                    const label = item[0].toUpperCase() + item.slice(-(item.length - 1))
                    tempList.push(
                        {
                            value: item,
                            text: label
                        }
                    )
                })
                setTitleList(tempList)
            }
        } catch { }
    }

    const physicianSpecialityList = async () => {
        try {
            const response = await getPhysicianSpecialities()
            if (response.success) {
                const data = response?.data
                setSpecialityList(getSemanticOptionsList(data, 'id', 'speciality'))
            }
        } catch { }
    }

    const physicianDocumentTypeList = async () => {
        try {
            const response = await getPhysicianDocumentTypes()
            if (response.success) {
                const data = response?.data
                setDocumentTypeList(getSemanticOptionsList(data, 'id', 'type'))
            }
        } catch { }
    }

    // get multi input datas

    const getPhysicianQualificationList = async () => {
        try {
            const response = await getPhysicianQualifications(basicId)
            if (response.success) {
                const data = response?.data
                setQualifications(getMultiInputList(data, 'id', 'qualification'))
            }
        } catch { }
    }

    // POST Functions

    const addPhysicianSpeciality = async (value) => {
        try {
            const data = JSON.stringify({
                speciality: value.value,
            })
            const response = await postPhysicianSpeciality(data)
            if (response?.success) {
                setSpeciality(response?.data?.id)
                physicianSpecialityList()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const addPhysicianDocumentType = async (value) => {
        try {
            const data = JSON.stringify({
                type: value.value,
            })
            const response = await postPhysicianDocumentType(data)
            if (response?.success) {
                setDocumentType(response?.data?.id)
                physicianDocumentTypeList()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const addPhysicianQualifications = async (value) => {
        if (basicId === '') {
            if (qualifications.length > 0) {
                var data = {
                    id: qualifications.length,
                    value: value
                }
            }
            else {
                var data = {
                    id: 0,
                    value: value
                }
            }
            setQualifications([...qualifications, data])
            return
        }
        try {
            const data = JSON.stringify({
                qualification: value,
            })
            const response = await postPhysicianQualification(basicId, data)
            if (response?.success) {
                getPhysicianQualificationList()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const handlePhysicianRegister = async () => {
        const physicianDetails = new FormData()
        physicianDetails.append("user_id", physicianId)
        physicianDetails.append("joined_date", joinedDate && new Date(joinedDate).toJSON())
        physicianDetails.append("title", title)
        physicianDetails.append("name", physicianName)
        physicianDetails.append("license_no", licenseNum)
        physicianDetails.append("speciality", speciality && speciality)
        physicianDetails.append("license_expiry_date", licenseExpDate && new Date(licenseExpDate).toJSON())
        physicianDetails.append("image", image)
        physicianDetails.append("email", email)
        physicianDetails.append("mobile", mobileNumber)
        physicianDetails.append("dob", dOB && new Date(dOB).toJSON())
        physicianDetails.append("gender", gender)
        physicianDetails.append("city", city)
        physicianDetails.append("pincode", pincode)
        physicianDetails.append("district", district)
        physicianDetails.append("state", state)
        physicianDetails.append("country", country)
        physicianDetails.append("address_line_1", address1)
        physicianDetails.append("address_line_2", address2)
        var tempID;
        console.log(physicianDetails)
        console.log(qualifications)
        try {
            const response = await postPhysicianRegister(physicianDetails)
            if (response.success) {
                if (!response?.message.includes('updated')) {
                    tempID = response?.data?.basic_info?.id
                    setBasicId(tempID)
                    qualifications.map(async (item) => {
                        try {
                            const data = JSON.stringify({
                                qualification: item.value,
                            })
                            const response = await postPhysicianQualification(tempID, data)
                            if (response?.success) {
                                getPhysicianQualificationList()
                            }
                            else {
                                Swal.fire("", response?.message, "error")
                                setBasicId('')
                                return
                            }
                        } catch { }
                    })
                }
                Swal.fire({
                    text: response?.message,
                    title: "Success",
                    icon: 'success',
                    timer: 1000,
                    showConfirmButton: false
                })
                handleTabChange()
            }
            else {
                Swal.fire("", response?.message, "error")
                setBasicId('')
                return
            }
        } catch {
            Swal.fire("", "Physician Creation Failed!!!", "error")
            setBasicId('')
        }
    }

    const handlePhysicianDocumentUpload = async () => {
        let documentData = new FormData()
        documentData.append("document_name", documentName)
        documentData.append("type", documentType)
        documentData.append("document_file", documentFile)
        // console.log(documentData)
        if (basicId) {
            try {
                const response = await postPhysicianDocuments(basicId, documentData)
                if (response?.success) {
                    setDocumentName('')
                    setDocumentType('')
                    setDocumentFile('')
                    getPhysicianDocumentList()
                }
                else {
                    Swal.fire("", response?.message, "error")
                }
            } catch { }
        }
        else {
            Swal.fire("", "Physician not created", "error")
            setKey('tab1')
        }
    }

    const handleShiftSubmit = async (e) => {
        e.preventDefault()
        const dataList = []
        if (fromTime && totime) {
            selectedDates.map((item) => {
                var tempData = {
                    'day': item,
                    'from_time': fromTime + ':00',
                    'to_time': totime + ':00'
                }
                dataList.push(tempData)
            })
            try {
                const data = {
                    shifts: dataList
                }
                const response = await postPhysicianShifts(basicId, data)
                if (response?.success) {
                    if (response?.data) {
                        const temp = response?.data;
                        const renderTableData = () => {
                            return temp.map((item) => {
                                return `<tr border="1">
                                  <td>${item.day}</td>
                                  <td>${item.from_time}</td>
                                  <td>${item.to_time}</td>
                                </tr>`;
                            });
                        };
                        const table = `
                      <h5><u>Overlaping Time Periods:</u></h5>
                      <table border="1" style="width:100%;">
                        <thead>
                          <tr border="1">
                            <th>Day</th>
                            <th>From Time</th>
                            <th>To Time</th>
                          </tr>
                        </thead>
                        <tbody>${renderTableData().join("")}</tbody>
                      </table>
                    `;
                        Swal.fire({
                            title: response?.message,
                            icon: "success",
                            html: table,
                        });
                    } else {
                        Swal.fire({
                            text: response?.message,
                            icon: "success",
                            timer: 1500,
                            showConfirmButton: false
                        });
                    }
                    getPhysicianShiftList()
                }
                else {
                    Swal.fire({
                        title: "Failed",
                        text: response?.message,
                        icon: "error",
                        timer: 1000,
                        showConfirmButton: false
                    });
                }
            } catch { }
        }
    }

    const handleConsultationDetails = async() => {
        try{
            const data = {}
            if(consultType){
                data['offline_fee']=consultFee
                data['has_offline_consultation']=true
            }
            else{
                data['online_fee']=consultFee
                data['has_online_consultation']=true
            }
            const response = await postPhysicianConsultationDetails(basicId, data)
            if (response?.success) {
                Swal.fire({
                    text: response?.message,
                    icon: "success",
                    timer: 1500,
                    showConfirmButton: false
                });
                setConsultFee('')
                setConsultType(true)
                getConsultationDetails()
            }
            else {
                Swal.fire({
                    title: "Failed",
                    text: response?.message,
                    icon: "error",
                    timer: 1000,
                    showConfirmButton: false
                });
            }
        } catch { }
    }

    // DELETE Functions

    const deleteQualification = async (dataId) => {
        if (basicId === '') {
            setQualifications(qualifications.filter(
                (item) => item.id !== dataId
            ))
            return
        }
        try {
            const response = await deletePhysicianQualification(basicId, dataId)
            if (response?.success) {
                getPhysicianQualificationList()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const deleteDocument = async (dataId) => {
        try {
            const response = await deletePhysicianDocuments(basicId, dataId)
            if (response?.success) {
                getPhysicianDocumentList()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }


    const deleteShift = async (dataId) => {
        try {
            if (selectedDates){
                const data = {}
                selectedDates.map((item, index)=>data['day'+index]=item)
                const response = await deletePhysicianShifts(basicId, dataId, data)
                if (response?.success) {
                    getPhysicianShiftList()
                }
                else {
                    Swal.fire("", response?.message, "error")
                }
            }
        } catch { }
    }


    // Other Functions

    const handleTabChange = () => {
        switch (key) {
            case "tab1":
                setKey('tab2')
                break
            case "tab2":
                setKey('tab3')
                break
            case "tab3":
                if (consultFee !== "" || consultFee === null){
                    handleConsultationDetails()
                    navigate('/physician-list')
                }
                else{
                    Swal.fire("Please enter the consultation amount" , "","info")
                }
                
        }

    }

    const handlePhysicianSearch = (item) => {
        setBasicId(item?.basic_info?.id)
        setPhysicianId(item?.user_id)
        setJoinedDate(item?.medical_id?.joined_date && new Date(item.medical_id.joined_date))
        setTitle(item?.medical_id?.title)
        setPhysicianName(item?.basic_info?.full_name)
        setLicenseNum(item?.medical_id?.license_no)
        setSpeciality(item?.medical_id?.speciality)
        setLicenseExpDate(item?.medical_id?.license_expiry_date && new Date(item.medical_id.license_expiry_date))
        setEmail(item?.basic_info?.email)
        setMobileNumber(item?.basic_info?.mobile)
        setDOB(item?.dob && new Date(item.dob))
        setGender(item?.gender)
        setCity(item?.city)
        setPincode(item?.pincode)
        setDistrict(item?.district)
        setState(item?.state)
        setCountry(item?.country)
        setAddress1(item?.address_line_1)
        setAddress2(item?.address_line_2)
    }


    const handleDateSelect = (item) => {
        if (singleSelect) {
            if (selectedDates.length > 0) {
                if (selectedDates[0] == item) {
                    setSelectedDates([])
                }
                else {
                    setSelectedDates([item]);
                }
            }
            else {
                setSelectedDates([item]);
            }
        }
        else {
            if (selectedDates.includes(item)) {
                setSelectedDates(
                    selectedDates.filter((element) => element != item)
                )
            }
            else {
                setSelectedDates([...selectedDates, item])
            }
        }
        console.log(selectedDates)
    }

    const handleFilterSelect = (value) => {
        switch (value) {
            case 'single':
                setSingleSelect(true)
                setSelectedDates(['mon'])
                break;
            case 'multi':
                setSingleSelect(false)
                break;
            case 'weekday':
                setSingleSelect(false)
                setSelectedDates(
                    [
                        'mon',
                        'tue',
                        'wed',
                        'thu',
                        'fri'
                    ]
                )
                break;
            case 'weekend':
                setSingleSelect(false)
                setSelectedDates(
                    [
                        'sat',
                        'sun'
                    ]
                )
                break;
            case 'all':
                setSingleSelect(false)
                setSelectedDates(
                    [
                        'mon',
                        'tue',
                        'wed',
                        'thu',
                        'fri',
                        'sat',
                        'sun'
                    ]
                )
                break;
            case 'clear':
                break;
            default:
                break;
        }
    }

    //<<<<<<<<<<<<<<<<<<<<<<<<<<< UseEffect Functions >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>


    useEffect(() => {
        if (!PhysicianEditData) {
            getPhysicianId()
        }
        physicianInfoChoice()
        physicianSpecialityList()
        physicianDocumentTypeList()
    }, [])

    useEffect(() => {
        if (basicId) {
            getPhysicianQualificationList()
            getPhysicianDocumentList()
            getPhysicianShiftList()
            getConsultationDetails()
        }
    }, [basicId])

    useEffect(()=>{
        if(basicId) {
            getPhysicianShiftList()
        }
    }, [selectedDates])

    return (
        <div className=''>
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                <div className='col-12'>
                    <Nav className='tabpane-container'>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab1'
                            >
                                <img
                                    src={physician_details}
                                    className='physician-details-icon'
                                    alt=''
                                />&nbsp;
                                Physician Details
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab2'
                            >
                                <img
                                    src={physician_documents}
                                    className='physician-documents-icon'
                                    alt=''
                                />&nbsp;
                                Documents
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab3'
                            >
                                <img
                                    src={physician_consultaion}
                                    className='physician-documents-icon'
                                    alt=''
                                />&nbsp;
                                Consultaion
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <div className='pagebody-container'>
                    <Tab.Content className='tabcontent-container'>
                        <TabPane
                            eventKey='tab1'
                            className=''
                        >
                            <PhysicianDetails
                                value={{
                                    ...{
                                        physicianId,
                                        joinedDate,
                                        title,
                                        physicianName,
                                        licenseNum,
                                        speciality,
                                        licenseExpDate,
                                        image,
                                        email,
                                        mobileNumber,
                                        dOB,
                                        gender,
                                        city,
                                        pincode,
                                        district,
                                        state,
                                        country,
                                        address1,
                                        address2,
                                        qualifications,
                                        titleList,
                                        specialityList,
                                    }
                                }}
                                setValue={{
                                    ...{
                                        setKey,
                                        setPhysicianId,
                                        setJoinedDate,
                                        setTitle,
                                        setPhysicianName,
                                        setLicenseNum,
                                        setSpeciality,
                                        setLicenseExpDate,
                                        setImage,
                                        setEmail,
                                        setMobileNumber,
                                        setDOB,
                                        setGender,
                                        setCity,
                                        setPincode,
                                        setDistrict,
                                        setState,
                                        setCountry,
                                        setAddress1,
                                        setAddress2,
                                    }
                                }}
                                functions={{
                                    ...{
                                        addPhysicianSpeciality,
                                        addPhysicianQualifications,
                                        handlePhysicianRegister,
                                        deleteQualification,
                                        handlePhysicianSearch,
                                    }
                                }}
                            />
                        </TabPane>
                        <TabPane
                            eventKey='tab2'
                            className=''
                        >
                            <PhysicianDocuments
                                value={{
                                    ...{
                                        documentName,
                                        documentType,
                                        documentFile,
                                        documentList,
                                        documentTypeList,
                                    }
                                }}
                                setValue={{
                                    ...{
                                        setKey,
                                        setDocumentName,
                                        setDocumentType,
                                        setDocumentFile,
                                    }
                                }}
                                functions={{
                                    ...{
                                        addPhysicianDocumentType,
                                        handlePhysicianDocumentUpload,
                                        deleteDocument,
                                        getPhysicianQualificationList,
                                        handleTabChange
                                    }
                                }}
                            />
                        </TabPane>
                        <TabPane
                            eventKey='tab3'
                            className=''
                        >
                            <PhysicianShift
                                value={{
                                    ...{
                                        selectedDates,
                                        basicId,
                                        shiftList,
                                        fromTime,
                                        totime,
                                        consultFee,
                                        consultType,
                                        consultationDetails
                                    }
                                }}
                                setValue={{
                                    ...{
                                        setKey,
                                        setFromTime,
                                        setToTime,
                                        setConsultFee,
                                        setConsultType
                                    }
                                }}
                                functions={{
                                    ...{
                                        handleTabChange,
                                        handleFilterSelect,
                                        handleDateSelect,
                                        handleShiftSubmit,
                                        handleConsultationDetails,
                                        deleteShift
                                    }
                                }}
                            />
                        </TabPane>
                    </Tab.Content>
                </div>
            </Tab.Container >
        </div>
    )
}

export default Registration
