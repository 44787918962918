import React, { useEffect, useRef, useState } from 'react'
import Swal from 'sweetalert2'
import { GrSearch } from 'react-icons/gr'
import { ButtonToolbar, OverlayTrigger, Popover } from 'react-bootstrap'
import './SearchBox.css'
import usePhysicianServices from '../../../services/api-services/physicianServices'

const PhysicianSearchBox = ({
    handleData,
    placeholder,
    icon,
    mobileOnly,
    onChange
}) => {
    const [showSearch, setShowSearch] = useState(false)
    const [focused, setFocused] = useState(false)
    const [search, setSearch] = useState('')
    const [physicianList, setPhysicianList] = useState('')
    const [containerWidth, setContainerWidth] = useState('')
    const [marginLeft, setMarginLeft] = useState('')

    const searchBox = useRef()
    const searchBtn = useRef()

    // console.log(containerWidth)

    const {
        getPhysicianList
    } = usePhysicianServices()

    const getPhysicianData = async () => {
        try {
            const data = {
                search: search
            }
            const response = await getPhysicianList(data)
            if (response?.success) {
                setPhysicianList(response?.data?.physicians)
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const handleSearch = (e) => {
        setSearch(e.target.value)
        try {
            onChange(e.target.value)
        } catch { }
    }

    useEffect(() => {
        if (mobileOnly ? !isNaN(search) : search) {
            if (mobileOnly && search.length == 10) {
                setShowSearch(false)
            }
            else {
                setShowSearch(true)
                getPhysicianData()
                setContainerWidth(
                    searchBox.current.offsetWidth +
                    (icon ? searchBtn.current.offsetWidth : 0)
                )
                setMarginLeft(
                    icon ? searchBtn.current.offsetWidth / 2 : 'auto'
                )
            }
        }
        else {
            setPhysicianList('')
            setShowSearch(false)
        }
    }, [search])

    useEffect(() => {
        setShowSearch(false)
    }, [])

    const searchOptionPopup = (
        <Popover
            className='patient-search-popup'
            style={
                {
                    "width": containerWidth,
                    "marginLeft": marginLeft
                }
            }
        >
            {physicianList.length > 0 ?
                physicianList.map((item, index) => {
                    const handleSelect = () => {
                        handleData(item)
                        setPhysicianList('')
                        if (mobileOnly) {
                            setSearch(item?.basic_info?.mobile)
                        }
                        else {
                            setSearch('')
                        }
                        setShowSearch(false)
                    }
                    return (
                        <div key={index} className='patient-search-options mouse-pointer row mx-0' onClick={handleSelect}>
                            <div className='col-8 basic-details'>
                                <div className="id">
                                    {item?.employee_id}
                                </div>
                                <div className="name">
                                    {item?.basic_info?.full_name}
                                </div>
                            </div>
                            <div className='col-4 mobile-number'>{item?.basic_info?.mobile}</div>
                        </div>
                    )
                })
                :
                'No Data Found'
            }
        </Popover>
    )
    const handleBlur=()=>{
        setShowSearch(false)
        setFocused(false)
    }
    const handleCancel = () => {
        setSearch('');
        handleBlur()
    };
    return (
        <div className="patient-search-box">
            <ButtonToolbar className='w-100'>
                <OverlayTrigger
                    show={showSearch}
                    trigger={['click']}
                    placement={"bottom"}
                    overlay={searchOptionPopup}
                >
                    <div
                        ref={searchBox}
                        className={`w-100 patient-search-container ${focused && 'highlight'}`}
                    >
                        <span className='px-3'>
                            <GrSearch color='#A5A5A5' size='15px' />
                        </span>
                        <input
                            onFocus={() => setFocused(true)}
                            onBlur={() => handleBlur()}
                            value={search}
                            placeholder={placeholder ? placeholder : 'Enter Physician ID or Mobile Number...'}
                            onChange={(e) => handleSearch(e)}
                        />
                        {search && (
                            <button onClick={handleCancel} className="cancel-button">
                                ✕
                            </button>
                        )}
                    </div>
                </OverlayTrigger>
            </ButtonToolbar>
            {icon && <span
                ref={searchBtn}
                onClick={() => { setShowSearch(!showSearch) }}
            >
                {icon}
            </span>}
        </div>
    )
}

export default PhysicianSearchBox
