import React, { useEffect, useRef, useState } from 'react'
import usePatientServices from '../../../services/api-services/patientServices'
import Swal from 'sweetalert2'
import { GrSearch } from 'react-icons/gr'
import { ButtonToolbar, OverlayTrigger, Popover } from 'react-bootstrap'
import './SearchBox.css'

const PatientSearchBox = ({
    handleData,
    placeholder,
    icon,
    mobileOnly,
    onChange,
    customButton,
    buttonText,
    buttonClick,
    toggleControl,
    toggleValue,
    defValue,
    handleReset,
}) => {
    const [showSearch, setShowSearch] = useState(false)
    const [focused, setFocused] = useState(false)
    const [search, setSearch] = useState('')
    const [patientList, setPatientList] = useState('')
    const [containerWidth, setContainerWidth] = useState('')
    const [marginLeft, setMarginLeft] = useState('')

    const searchBox = useRef()
    const searchBtn = useRef()

    // console.log(containerWidth)

    const {
        getPatientList
    } = usePatientServices()

    const getPatientData = async () => {
        try {
            const data = {
                search: search
            }
            const response = await getPatientList(data)
            if (response?.success) {
                console.log("#################",response?.data?.patients)
                setPatientList(response?.data?.patients)
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const handleSearch = (e) => {
        if(toggleValue){
            toggleControl(false)
        }
        if(defValue){
            onChange(e.target.value)
        }
        else{
            try{
                onChange(e.target.value)
            }catch{}
            setSearch(e.target.value)
        }     
    }

    useEffect(() => {
        if (search && (!mobileOnly || !isNaN(search))) {
            if (mobileOnly && search.length>=10 && search.length<13) {
                setShowSearch(false);
            } else {
                setShowSearch(true);
                getPatientData();
                setContainerWidth(
                    searchBox.current.offsetWidth +
                    (icon ? searchBtn.current.offsetWidth : 0)
                );
                setMarginLeft(
                    icon ? searchBtn.current.offsetWidth / 2 : 'auto'
                );
            }
        } else {
            setPatientList('');
            setShowSearch(false);
        }
    }, [search]);
    

    useEffect(()=>{
        if(toggleValue){
            setSearch('')
            setShowSearch(false)
        }
    }, [toggleValue])

    useEffect(()=>{
        setShowSearch(false)
    },[])

    useEffect(()=>{
        if(defValue){
            setSearch(defValue)
        }
        else{
            setSearch('')
        }
    },[defValue])

    const searchOptionPopup = (
        <Popover
            className='patient-search-popup'
            style={
                {
                    "width":containerWidth,
                    "marginLeft":marginLeft
                }
            }
        >
            <div className='patient-search-content'>
                {patientList.length>0 ?
                    patientList.map((item, index) => {
                        const handleSelect = () => {
                            handleData(item)
                            setPatientList('')
                            if(mobileOnly){
                                setSearch(item?.mobile)
                            }
                            else{
                                setSearch('')
                            }
                            setShowSearch(false)
                        }
                        return (
                                    <div key={index} className='patient-search-options mouse-pointer row mx-0' onClick={()=>handleSelect()}>
                                        <div className='col-8 basic-details'>
                                            <div className="id">
                                                {item.patient_id}
                                            </div>
                                            <div className="name">
                                                {item.full_name}
                                            </div>
                                        </div>
                                        <div className='col-4 mobile-number'>{item.mobile}</div>
                                    </div>
                        )
                    })
                    :
                    'No Data Found'
                }
            </div>
            {customButton&&
                <div>
                    <button 
                        onClick={()=>buttonClick(search)}
                        className="btn btn-dark w-100"
                    >
                        {buttonText?buttonText:'Add'}
                    </button>                
                </div>
            }
        </Popover>
    )
    const handleBlur=()=>{
        setShowSearch(false)
        setFocused(false)
    }
    const handleCancel = () => {
        setSearch('');
        handleReset()
        console.log("cancelled...")
        handleBlur()
    };
    return (
        <div className="patient-search-box">
            <ButtonToolbar className='w-100'>
                <OverlayTrigger
                    show={showSearch}
                    trigger={['click']}
                    placement={"bottom"}
                    overlay={searchOptionPopup}
                >
                    <div 
                        ref={searchBox} 
                        className={`w-100 patient-search-container ${focused&&'highlight'}`}
                    >
                        <span className='px-3'>
                            <GrSearch color='#A5A5A5' size='15px' />
                        </span>
                        <input
                            onFocus={() => setFocused(true)}
                            onBlur={()=>handleBlur()}
                            value={search}
                            placeholder={placeholder?placeholder:'Enter Patient ID or Mobile Number...'}
                            onChange={(e) => handleSearch(e)}
                        />
                        {search && (
                            <button onClick={handleCancel} className="cancel-button">
                                ✕
                            </button>
                        )}
                    </div>
                </OverlayTrigger>
            </ButtonToolbar>
            {icon&&<span
                ref={searchBtn}
                onClick={() => { setShowSearch(!showSearch) }}
            >
                {icon}
            </span>}
        </div>
    )
}

export default PatientSearchBox
