// import { Height } from '@mui/icons-material'
import React, { useState } from 'react'
import { Form } from 'react-bootstrap'
// import { FiSearch } from 'react-icons/fi'
import { TimePicker } from 'react-ios-time-picker'
import { useNavigate } from 'react-router-dom'
import Swal from 'sweetalert2'

const PhysicianShift = ({ value, setValue, functions }) => {
    const [tempTime1, setTempTime1] = useState()
    const [tempTime2, setTempTime2] = useState()
    const navigate = useNavigate()


    const handleFromTime = (e) => {
        var adm;
        if (e.slice(-2) === "PM") {
            var num = Number(e.slice(0, 2))
            if (num != 12) {
                num = num + 12
            }
            adm = num + e.slice(2, 5)
        } else {
            var num = Number(e.slice(0, 2))
            if (num == 12) {
                num = '00'
            }
            adm = num + e.slice(2, 5)
        }
        console.log(adm)
        setTempTime1(e)
        setValue?.setFromTime(adm)
    }

    const handleToTime = (e) => {
        var adm;
        if (e.slice(-2) === "PM") {
            var num = Number(e.slice(0, 2))
            if (num != 12) {
                num = num + 12
            }
            adm = num + e.slice(2, 5)
        } else {
            var num = Number(e.slice(0, 2))
            if (num == 12) {
                num = '00'
            }
            adm = num + e.slice(2, 5)
        }
        if (adm.slice(0, 2) < value?.fromTime.slice(0, 2)) {
            Swal.fire("", "To time should be greater than From time", 'error')
            setTempTime2(tempTime1)
            setValue?.setToTime(null)
            return
        }
        else if (adm.slice(0, 2) == value?.fromTime.slice(0, 2) && (adm.slice(3, 5) < value?.fromTime.slice(3, 5))) {
            Swal.fire("", "To time should be greater than From time", 'error')
            setTempTime2(tempTime1)
            setValue?.setToTime(null)
            return
        }
        else {
            setTempTime2(e)
            setValue?.setToTime(adm)
        }
    }

    return (
        <div className='row mx-0'>
            <div className="col-12 pb-4 pt-5 page-title-label">Consultation Shift</div>
            <div className="col-10 col-11 consultation-shift-dates px-0">
                <div
                    className={`consultation-dates-btn ${value?.selectedDates.includes('mon') ? 'active' : ''}`}
                    onClick={() => functions?.handleDateSelect('mon')}
                >
                    Monday
                </div>
                <div
                    className={`consultation-dates-btn ${value?.selectedDates.includes('tue') ? 'active' : ''}`}
                    onClick={() => functions?.handleDateSelect('tue')}
                >
                    Tuesday
                </div>
                <div
                    className={`consultation-dates-btn ${value?.selectedDates.includes('wed') ? 'active' : ''}`}
                    onClick={() => functions?.handleDateSelect('wed')}
                >
                    Wednesday
                </div>
                <div
                    className={`consultation-dates-btn ${value?.selectedDates.includes('thu') ? 'active' : ''}`}
                    onClick={() => functions?.handleDateSelect('thu')}
                >
                    Thursday
                </div>
                <div
                    className={`consultation-dates-btn ${value?.selectedDates.includes('fri') ? 'active' : ''}`}
                    onClick={() => functions?.handleDateSelect('fri')}
                >
                    Friday
                </div>
                <div
                    className={`consultation-dates-btn ${value?.selectedDates.includes('sat') ? 'active' : ''}`}
                    onClick={() => functions?.handleDateSelect('sat')}
                >
                    Saturday
                </div>
                <div
                    className={`consultation-dates-btn ${value?.selectedDates.includes('sun') ? 'active' : ''}`}
                    onClick={() => functions?.handleDateSelect('sun')}
                >
                    Sunday
                </div>
            </div>
            <div className="col-1 col-2">
                <Form.Select
                    className='appointment-status-dropdown'
                    onChange={(e) => functions?.handleFilterSelect(e.target.value)}
                >
                    <option value="single">Single Select</option>
                    <option value="multi">Multi Select</option>
                    <option value="weekday">Weekdays</option>
                    <option value="weekend">Weekend</option>
                    <option value="all">All</option>
                </Form.Select>
            </div>
            <div className="col-12 pt-4 page-title-label">Shift Time</div>
            <div className='left-split-container col-6 row'>
                    <Form onSubmit={functions?.handleShiftSubmit} className="col-12 row mx-0 d-flex">
                        <Form.Group className={'col-5 mb-3'}>
                            <Form.Label className='label-text-content'>From</Form.Label>
                            <TimePicker
                            inputClassName='adm-input-timepicker'
                            placeHolder='From Time'
                            use12Hours
                            onSave={handleFromTime}
                            value={tempTime1}
                            />
                        </Form.Group>
                        <Form.Group className={'col-5 mb-3'}>
                            <Form.Label className='label-text-content'>To</Form.Label>
                            <TimePicker
                            inputClassName='adm-input-timepicker'
                            placeHolder='To Time'
                            use12Hours
                            onSave={handleToTime}
                            value={tempTime2}
                            />
                         </Form.Group>
                        <div className="col-2 d-flex align-items-start pt-4 px-0">
                            <button type="submit" className="search-icon-btn fs-5">+</button>
                        </div>
                    </Form>
                    <div className="col-12 row mx-0 d-flex  align-items-start">
                        <Form.Group className={'col-5 mb-3'}>
                        <Form.Label className='label-text-content my-0'>
                            Fees
                        </Form.Label>
                        <Form.Control
                            type='text'
                            className='input-password-control my-0 text-center'
                            value={value?.consultFee}
                            onChange={(e)=>setValue?.setConsultFee(e.target.value)}
                        />
                        </Form.Group>
                    </div>
            </div>
            <div className='col-6 row ms-2 ps-3 pe-0 me-0'>
                <div className="col-12">
                    <div className="physician-shift-container">
                        <div className='align-items-center row mx-0 px-3 ps-2 mb-1 bg-light py-3' style={{position: 'sticky',top: '10px', zIndex:4}} >
                            <div className='doc-list-title col-5 ps-3'>From</div>
                            <div className='doc-list-title col-5'>To</div>
                            <div className='col-2' />
                        </div>
                        <div className={`mx-0 row p-2`}>
                            {value?.shiftList && value?.shiftList.map((data, index) => {
                                const changeTime = (hms) => {
                                    var target = new Date("1970-01-01T" + hms);
                                    return target.toLocaleTimeString()
                                }
                                return (
                                    <div key={index} className={`d-flex doc-list-data col-12 row ms-0 ps-1 pe-0`}>
                                        <div className='text col-5 pe-0'>{changeTime(data?.from_time)}</div>
                                        <div className="text col-5">{changeTime(data?.to_time)}</div>
                                        <div className="text justify-content-end px-0 mx-0 col-2 d-flex">
                                            <div
                                                className='shift-remove-btn fs-5 '
                                                onClick={()=>functions?.deleteShift(data?.id)}
                                            >
                                                <span>
                                                    -
                                                </span>
                                            </div>
                                        </div>
                                    </div>
                                )
                            })}
                        </div>
                    </div>
                    <div className='row mx-0 px-4 physician-fee-container d-flex align-items-center mt-4'>
                        <div className="col-2 px-0 text text-end">Consultation Fee&nbsp;:</div>
                        <div className="col-2 pe-0 text">₹ {value?.consultFee||0}/-</div>
                        <span className="col-4" />
                        {/* <div className="col-2 px-0 text text-end">Online&nbsp;:</div>
                        <div className="col-2 pe-0 text">{value?.consultationDetails?.online_fee}</div> */}
                    </div>
                </div>
            </div>
            <span className='col-9 mt-5' />
            <div className="col-3 mt-5 row d-flex justify-content-end mx-0 pe-3">
                <div
                    className='form-cancel-btn btn col-6 me-3'
                    onClick={()=>navigate('/physician-register')}
                >
                    Cancel
                </div>
                <div
                    className='form-next-btn btn col-6 me-0'
                    onClick={functions?.handleTabChange}
                >
                    Save & Next
                </div>
            </div>
        </div>
    )
}

export default PhysicianShift
