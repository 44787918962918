import React, { useEffect, useState } from 'react'
import LeaveForm from './components/LeaveForm'
import LeaveList from './components/LeaveList'
import usePhysicianServices from '../../services/api-services/physicianServices'

const LeaveRequests = () => {
  const {getLeaveList,postLeaveRequest,putLeaveRequest,delLeaveRequest,getPhysicianList} = usePhysicianServices()
  const [physician, setPhysician] = useState();
  const [days, setDays] = useState();
  const [fromDate, setFromDate] = useState(null);
  const [toDate, setToDate] = useState(null);
  const [leaveReason, setLeaveReason] = useState('');
  const [phyData,setPhyData] =useState([]);
  const [isDisabled, setIsDisabled] = useState(true);
  const [allLeaveRequests, setAllLeaveRequests] = useState([]);
  const [editID,setEditID] =useState(false)
  const getallLeaveRequests = async () =>{
    try{
      const response = await getLeaveList()
      if (response?.success){
        console.log(response?.data)
        setAllLeaveRequests(response?.data)
      }
    }catch(err){
      console.log(err)
    }
  }

  const getallPhyData = async () => {
    const response = await getPhysicianList();
    if (response?.success) {
      const temp = response?.data?.physicians;
      const updatedList = temp.map((d,i) =>(
        {
          docID: d?.id,
          docName: d?.basic_info?.first_name || 'Doctor',
        }));
      setPhyData(updatedList); 
    }
  };

  useEffect(()=>{
    getallLeaveRequests()
    getallPhyData()
  },[])

  const handleReset = async()=>{
    setPhysician('');
    setDays('');
    setFromDate('');
    setToDate('');
    setLeaveReason('');
    setIsDisabled(true);
    setEditID(false);
  }
  
  return (
    <div className='mx-5'>
      <div className='fw-bold'>Leave Requests</div>
      <div>
        <LeaveForm {...{
          postLeaveRequest,
          physician,
          phyData,
          leaveReason,
          toDate,
          fromDate,
          days,
          isDisabled,
          editID,

          setPhysician,
          setPhyData,
          setLeaveReason,
          setToDate,
          setFromDate,
          setDays,
          setIsDisabled,
          setEditID,

          getallLeaveRequests,
          putLeaveRequest,
          handleReset,
        }}/>
      </div>
      <div><LeaveList {...{
        allLeaveRequests,
        delLeaveRequest,
        getallLeaveRequests,
        setPhysician,
        setPhyData,
        setLeaveReason,
        setToDate,
        setFromDate,
        setDays,
        setIsDisabled,
        setEditID,
        setAllLeaveRequests,
        }}/></div>
    </div>
  )
}

export default LeaveRequests