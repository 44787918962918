import React from 'react'
import useAxiosPrivate from '../../hooks/useAxiosPrivate'
import useAxios from '../../hooks/useAxios'

const useAccountServices = () => {
    const axiosPrivate = useAxiosPrivate()
    const axios = useAxios()

    // GET REQUESTS

    //get card content templates

    const getCardTemplates = async (data) => {
      const response = await axiosPrivate.get('/account/card_templates/', {
        params: {
          card_type:data,
        }
      })
      return response?.data
    }

    const getDashboard = async (data) => {
      const response = await axiosPrivate.get('/account/dashboard/')
      return response?.data
    }

    //get all id types

    const getIDTypes = async () => {
      const response = await axiosPrivate.get('/account/id_types')
      return response?.data
    }

    //get user id's

    const getNewID = async (data) => {
      const response = await axiosPrivate.get('/account/config/id/', {
        params: {
          id_type:data,
        }
      })
      return response?.data
    }

    // POST REQUESTS

    //login api

    const login = async (data) => {

        try {
            const response = await axios.post('/account/login/', data)
            return response.data
        } catch (err) {
            throw new Error("Login fialed")
        }
    };

    //post id config

    const postIdCofig = async (data) => {
      const response = await axiosPrivate.post('/account/config/id/', data)
      return response?.data
    }

    // PUT REQUESTS

    // DELETE REQUESTS

  return {
    // GET services
    getCardTemplates,
    getIDTypes,
    getNewID,
    getDashboard,
    // POST services
    login,
    postIdCofig,

    // PUT services

    // DELETE services

  }
}

export default useAccountServices
