import useAxiosPrivate from "../../hooks/useAxiosPrivate";
import useAxios from "../../hooks/useAxios";

const usePhysicianServices = () => {
  const axiosPrivate = useAxiosPrivate();
  const axios = useAxios();

  //GET REQUESTS

  //get physician info choices
  const getPhysicianInfoChoices = async () => {
    const response = await axiosPrivate.get(
      "physician/physician_info_choices/"
    );
    return response.data;
  };

  const getSortFilterPagination = async (data) => {
    const response = await axiosPrivate.get(`physician/physicians/`, {
      params: { ...data },
    });
    return response.data;
  };

  //get a single physician detail
  const getSinglePhysician = async (data) => {
    const response = await axiosPrivate.get(
      `physician/physician_detail/${data}/`
    );
    return response.data;
  };

  //get a single physician detail
  const physicianDelete = async (pid, password) => {
    const response = await axiosPrivate.delete(
      `physician/physician/delete/${pid}/`,
      {
        data: { password: password },
      }
    );
    return response.data;
  };

  const physicianAction = async (pid, data) => {
    const response = await axiosPrivate.post(
      `physician/physician/activate/${pid}/`,
      {
        action: data,
      }
    );
    return response.data;
  };

  // get physician speciality list

  const getPhysicianSpecialities = async () => {
    const response = await axiosPrivate.get("physician/physician_speciality/");
    return response.data;
  };

  // get physician document types

  const getPhysicianDocumentTypes = async () => {
    const response = await axiosPrivate.get(
      "physician/physician_document_type/"
    );
    return response.data;
  };

  //get new physician id

  const getNewPhysicianId = async () => {
    const response = await axiosPrivate.get("physician/physician_id/");
    return response.data;
  };

  // get physician qualification list

  const getPhysicianQualifications = async (id) => {
    const response = await axiosPrivate.get(
      `physician/physician_qualifications/${id}/`
    );
    return response.data;
  };

  // get physician documents

  const getPhysicianDocuments = async (id) => {
    const response = await axiosPrivate.get(
      `physician/physician_document_record/${id}/`
    );
    return response.data;
  };

  //get physician Shifts

  const getPhysicianShifts = async (id, data) => {
    const response = await axiosPrivate.get(
      `physician/physician_shifts/${id}/`,
      {
        params: { ...data },
      }
    );
    return response.data;
  };

  //get physician Consultation Details

  const getPhysicianConsultationDetails = async (id) => {
    const response = await axiosPrivate.get(
      `physician/physician_consultation_details/${id}/`
    );
    return response.data;
  };

  //get physician List

  const getPhysicianList = async (data) => {
    const response = await axiosPrivate.get("physician/physicians/", {
      params: { ...data },
    });
    return response.data;
  };

  //get physician details

  const getPhysicianDetails = async (id) => {
    const response = await axiosPrivate.get(
      `physician/physician_detail/${id}/`,
      {}
    );
    return response.data;
  };

  const getLeaveList =  async () => {
    const response = await axiosPrivate.get("/physician/admin/physician/leave/create/");
    return response.data;
  }
  //POST REQUESTS

  //post physician speciality

  const postPhysicianSpeciality = async (data) => {
    const response = await axiosPrivate.post(
      `physician/physician_speciality/`,
      data
    );
    return response.data;
  };

  //post physician document type

  const postPhysicianDocumentType = async (data) => {
    const response = await axiosPrivate.post(
      `physician/physician_document_type/`,
      data
    );
    return response.data;
  };

  //post physician register

  const postPhysicianRegister = async (data) => {
    const response = await axiosPrivate.post(
      "account/physician_register/",
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  };

  //post physician qualification

  const postPhysicianQualification = async (id, data) => {
    const response = await axiosPrivate.post(
      `physician/physician_qualifications/${id}/`,
      data
    );
    return response.data;
  };

  //post physician document

  const postPhysicianDocuments = async (id, data) => {
    const response = await axiosPrivate.post(
      `physician/physician_document_record/${id}/`,
      data,
      {
        headers: { "Content-Type": "multipart/form-data" },
      }
    );
    return response.data;
  };

  //post physician Shift

  const postPhysicianShifts = async (id, data) => {
    const response = await axiosPrivate.post(
      `physician/physician_shifts/${id}/`,
      data
    );
    return response.data;
  };

  //post physician Consultation Details

  const postPhysicianConsultationDetails = async (id, data) => {
    const response = await axiosPrivate.post(
      `physician/physician_consultation_details/${id}/`,
      data
    );
    return response.data;
  };

  const postLeaveRequest =  async (data) => {
    const response =await axiosPrivate.post("/physician/admin/physician/leave/create/",data);
    return response.data;
  }

  // PUT REQUESTS

  const putLeaveRequest =  async (id,data) => {
    const response =await axiosPrivate.put(`/physician/admin/leave/update/${id}/`,data);
    return response.data;
  }
  //DELETE REQUESTS

  const delLeaveRequest =  async (id) => {
    const response =await axiosPrivate.delete(`/physician/admin/leave/update/${id}/`);
    return response.data;
  }
  //delete physician speciality

  const deletePhysicianSpeciality = async (dataId) => {
    const response = await axiosPrivate.delete(
      `physician/physician_speciality/${dataId}/delete/`
    );
    return response.data;
  };

  //delete physician document type

  const deletePhysicianDocumentType = async (dataId) => {
    const response = await axiosPrivate.delete(
      `physician/physician_document_type/${dataId}/delete/`
    );
    return response.data;
  };

  //delete physician qualification

  const deletePhysicianQualification = async (userId, dataId) => {
    const response = await axiosPrivate.delete(
      `physician/physician_qualifications/${userId}/${dataId}/delete/`
    );
    return response.data;
  };

  //delete physician document

  const deletePhysicianDocuments = async (userId, dataId) => {
    const response = await axiosPrivate.delete(
      `physician/physician_document_record/${userId}/${dataId}/delete/`
    );
    return response.data;
  };

  //delete physician shift

  const deletePhysicianShifts = async (userId, dataId, data) => {
    const response = await axiosPrivate.delete(
      `physician/physician_shifts/${userId}/${dataId}/delete/`,
      {
        params: { ...data },
      }
    );
    return response.data;
  };

  return {
    //GET services
    getPhysicianInfoChoices,
    getPhysicianSpecialities,
    getPhysicianDocumentTypes,
    getNewPhysicianId,
    getPhysicianQualifications,
    getPhysicianDocuments,
    getPhysicianShifts,
    getPhysicianConsultationDetails,
    getPhysicianList,
    getPhysicianDetails,
    getSinglePhysician,
    getSortFilterPagination,
    getLeaveList,

    //POST services
    postPhysicianSpeciality,
    postPhysicianDocumentType,
    postPhysicianRegister,
    postPhysicianQualification,
    postPhysicianDocuments,
    postPhysicianShifts,
    postPhysicianConsultationDetails,
    postLeaveRequest,
    physicianAction,

    // PUT services
    putLeaveRequest,
    // DELETE services
    deletePhysicianSpeciality,
    deletePhysicianDocumentType,
    deletePhysicianQualification,
    deletePhysicianDocuments,
    deletePhysicianShifts,
    physicianDelete,
    delLeaveRequest,
  };
};

export default usePhysicianServices;
