import React, { useState } from 'react';
import { Bar } from 'react-chartjs-2';
import { CalendarOutlined ,DownOutlined } from '@ant-design/icons';
import { Button, Dropdown, Space } from 'antd';
import {
    Chart as ChartJS,
    CategoryScale,
    LinearScale,
    BarElement,
    Title,
    Tooltip,
    Legend
} from 'chart.js';

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const DashboardChart = (params) => {
    const {weekNames,weekData,monthData,monthNames}=params;
    const jsMonths = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sept", "Oct", "Nov", "Dec"];
    const jsDays = ["Sun", "Mon", "Tue", "Wed", "Thu", "Fri", "Sat"];
    const currentMonthIndex = new Date().getMonth();
    const currentDayIndex = new Date().getDay();
    const currentMonthName = jsMonths[currentMonthIndex];
    const currentDayName = jsDays[currentDayIndex];
    const currentMonth = monthNames.indexOf(currentMonthName);
    const currentDay = weekNames.indexOf(currentDayName);
    const [graphData, setGraphData] = useState('weekly');
    const maxWeek = Math.ceil((Math.max(...weekData) + 10)/10)*10;
    const maxMonth = Math.ceil((Math.max(...monthData) + 20)/10)*10;

    const monthlyData = {
        labels: monthNames,
        datasets: [{
            label: 'Appointments',
            data: monthData,
            backgroundColor: Array(12).fill('#F6CEA3').map((color, index) => 
                index === currentMonth ? '#f1ad5f' : color
            ),
            borderColor: '#F6CEA3',
            borderWidth: 2,
            borderRadius: 4,
            barThickness: 30, 
            hoverBackgroundColor: "f1ad5f"
        }]
    };

    const weeklyData = {
        labels: weekNames,
        datasets: [{
            label: 'Appointments',
            data: weekData,
            backgroundColor: Array(7).fill('#F6CEA3').map((color, index) => 
                index === currentDay ? '#f1ad5f' : color
            ),
            borderColor: '#F6CEA3',
            borderWidth: 2,
            borderRadius: 4,
            barThickness: 30, 
            hoverBackgroundColor: "f1ad5f"
        }]
    };

    const options = {
        responsive: true,
        maintainAspectRatio: false,
        scales: {
            x: {
                grid: {
                    display: false,
                },
                border: {
                    display: false, 
                },
                ticks: {
                    font: {
                      size: 15, 
                    },
                    color: '#b8b8b8',
                  },
            },
            y: {
                beginAtZero: true,
                max: graphData === 'weekly' ? maxWeek:maxMonth,
                border: {
                    display: false,
                },
                ticks: {
                    font: {
                      size: 18, 
                    },
                    color: '#b8b8b8',
                  },
            }
        },
        plugins: {
            legend: {
                display: false
            },
            tooltip: {
                enabled: true
            }
        }
    };

    const items = [
        {
            label: 'Weekly',
            key: 'weekly',
        },
        {
            label: 'Monthly',
            key: 'monthly',
        },
    ];

    const handleMenuClick = (e) => {
        console.log(e)
        setGraphData(e.key);
    };

    const menuProps = {
        items,
        onClick: handleMenuClick,
    };

    return (
        <div style={{height: '220px'}}>
            <div className='mb-4 d-flex justify-content-end'>
                <Dropdown menu={menuProps}>
                    <Button>
                        <Space>
                            <CalendarOutlined/>
                            {graphData.charAt(0).toUpperCase() + graphData.slice(1)} 
                            <DownOutlined />
                        </Space>
                    </Button>
                </Dropdown>
            </div>
            
            <Bar data={graphData === 'weekly' ? weeklyData : monthlyData} options={options} />
        </div>
    );
};

export default DashboardChart;
