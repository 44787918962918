import patient_details from '../../../../../assets/pictures/icons/patient-details-black-icon.png'
import physician_add from '../../../../../assets/pictures/icons/physician-add-white-icon.png'

import React, { useEffect, useState } from 'react'
import { MdKeyboardArrowRight } from 'react-icons/md'
import Calendar from 'react-calendar'
import { Form, Modal, Nav, Tab, TabPane } from 'react-bootstrap'
import { useLocation, useNavigate } from 'react-router-dom'
import './MakeAppointment.css'
import Swal from 'sweetalert2'
import moment from 'moment'
import PatientSearchBox from '../../../../shared/form-components/search-box/PatientSearchBox'
import usePhysicianServices from '../../../../services/api-services/physicianServices'
import useGetListDatas from '../../../../services/other-services/getListDatas'
import DropDownBox from '../../../../shared/form-components/dropdown/CustomDropDown'
import InputTextBox from '../../../../shared/form-components/form-text/InputTextBox'
import useConsultationServices from '../../../../services/api-services/consultationServices'
import useAccountServices from '../../../../services/api-services/accountServices'

const MakeAppointment = () => {
    const [showPatientCreateModal, setShowPatientCreateModal] = useState(false)
    const [searchClose, setSearchClose] = useState(false)
    const [tempPatient, setTempPatient] = useState(false)
    // const [patientList, setPatientList] = useState('')
    const [basicId, setBasicId] = useState('')
    const [patientName, setPatientName] = useState('')
    const [lastName, setLastName] = useState('')
    const [patientRegistered, setPatientRegistered] = useState(new Date())
    const [patientMobile, setPatientMobile] = useState('')
    const [patientEmail, setPatientEmail] = useState('')
    const [patientType, setPatientType] = useState('')
    const [patientAddress, setPatientAddress] = useState('')
    const [patientAge, setPatientAge] = useState('')

    const [speciality, setSpeciality] = useState("")
    const [physician, setPhysician] = useState("")
    const [shift, setShift] = useState("")
    const [appointmentDate, setAppointmentDate] = useState(new Date())
    const [appointmentId, setAppointmentId] = useState('')

    const [specialityList, setSpecialityList] = useState("")
    const [physicianList, setPhysicianList] = useState("")
    const [shiftList, setShiftList] = useState([])

    const navigate = useNavigate()
    const location = useLocation()

//<<<<<<<<<<<<<<<<<<<<<<<<<<< Service Declarations >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    
    // GET APIS
    const {
        getPhysicianSpecialities,
        getPhysicianList,
        getPhysicianShifts
    } = usePhysicianServices()

    const { 
        postPatientAppointment,
        putPatientAppointment,
        getPatientDetail,
    } = useConsultationServices()

    const {
        getNewID,
    } = useAccountServices()

    // External Functions
    const {
        getSemanticOptionsList
    } = useGetListDatas()

//<<<<<<<<<<<<<<<<<<<<<<<<<<< Function Declarations >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>

    const getAppointmentDetails = async (appointment_id) => {
        try{
            const response = await getPatientDetail(appointment_id)
            if (response?.success){
                const data = response?.data
                handleSearch(data?.patient_details?.details)
                setSpeciality(data?.fk_speciality)
                setPhysician(data?.fk_physician)
                setShift(data?.fk_shift)
                setAppointmentId(data?.appointment_id)
                setAppointmentDate(new Date(data?.appointment_date))
            }
        }catch(err){
            console.log(err)
        }
    }

    const getAppointmentId = async () => {
        try {
            const response = await getNewID('appointment_id')
            if (response.success) {
                setAppointmentId(response?.data?.complete_id)
            }
        } catch { }
    }

    const physicianSpecialityList = async () => {
        try {
            const response = await getPhysicianSpecialities()
            if (response.success) {
                const data = response?.data
                // setSpecialityList(getDropdownList(data, 'id', 'speciality'))
                setSpecialityList(getSemanticOptionsList(data, 'id', 'speciality'))
            }
        } catch { }
    }

    const physicianListData = async () => {
        try {
            const param = {
                speciality:speciality
            }
            const response = await getPhysicianList(param)
            if (response.success) {
                const data = response?.data?.physicians
                var templist = []
                data.map((item)=>{
                    templist.push(
                        {
                            text: item?.basic_info?.full_name,
                            value: item?.basic_info?.id
                        }
                    )
                })
                setPhysicianList(templist)
            }
        } catch { }
    }

    const shiftListData = async () => {
        try {
            var day;
            switch(appointmentDate.getDay()){
                case 0: day='sun'
                    break;
                case 1: day='mon'
                    break;
                case 2: day='tue'
                    break;
                case 3: day='wed'
                    break;
                case 4: day='thu'
                    break;
                case 5: day='fri'
                    break;
                case 6: day='sat'
                    break;
            }
            const param = {
                day0:day
            }
            const response = await getPhysicianShifts(physician, param)
            if (response.success) {
                const data = response?.data
                var templist = []
                data.map((item)=>{
                    const changeTime = (hms) => {
                        var target = new Date("1970-01-01T" + hms);
                        return target.toLocaleTimeString()
                    }
                    var fromTime = changeTime(item?.from_time)
                    var toTime = changeTime(item?.to_time)
                    templist.push(
                        {
                            text: `${fromTime} - ${toTime}`,
                            value: item?.id
                        }
                    )
                })
                setShiftList(templist)
            }
            else{
                setShiftList([])
            }
        } catch { 
            setShiftList([])
        }
    }

    const handleTempPatientSubmit = async(e) => {
        e.preventDefault()
        if(patientName.length<=0 || patientAddress.length<=0 || patientMobile.length<=0){
            Swal.fire({
                text:"Fill out required fields",
                icon:'info',
                timer:1000,
                showConfirmButton:false
            })
        }
        else if(isNaN(patientMobile)||patientMobile.length<10){
            Swal.fire({
                text:"Enter valid mobile number",
                icon:'info',
                timer:1000,
                showConfirmButton:false
            })
        }
        else{
            setTempPatient(true)
            setShowPatientCreateModal(false)
        }
    }

    const handleTempPatientCancel = async() => {
        setTempPatient(false)
        setPatientName('') 
        setLastName('')
        setPatientMobile('')
        setPatientAddress('')
        setPatientEmail('')
        setPatientAge('')
        setShowPatientCreateModal(false)
    }

    const handleSearch = (item) =>{
        setTempPatient(false)
        setBasicId(item?.id?item.id:'')
        setPatientName(item?.full_name?item.full_name:'')
        setPatientRegistered(item?.medical_record?.admission_date?new Date(item.medical_record.admission_date):new Date())
        setPatientMobile(item?.mobile?item.mobile:'')
        setPatientEmail(item?.email?item.email:'')
        setPatientType(item?.patient_type?item.patient_type:'')
        setPatientAddress(
            `${item?.address_line_1?item.address_line_1:''}
            ${item?.address_line_2?', '+item.address_line_2:''}
            ${item?.city?', '+item.city:''}
            ${item?.district?', '+item.district:''}
            ${item?.state?', '+item.state:''}
            ${item?.country?', '+item.country+(item?.pincode&&' - '):''}
            ${item?.pincode&&' PIN : '+item.pincode}`
        )
    }

    const handleFinalSubmit = async(e) =>{
        e.preventDefault()
        try{
            if(physician.length<=0 || speciality.length<=0 || shift.length<=0){
                Swal.fire({
                    text:"Select required options",
                    icon:'info',
                    timer:1000,
                    showConfirmButton:false
                })
            }
            else{
                var app_date = appointmentDate.getTime()
                app_date = new Date(app_date + 5.5 * 60 * 60 * 1000)
                var data={
                    fk_physician:physician,
                    fk_speciality:speciality,
                    fk_shift:shift,
                    appointment_id:appointmentId,
                    appointment_date:app_date.toISOString().slice(0,10)
                }

                if(tempPatient){
                    data['is_registered_patient']=false
                    data['first_name']=patientName
                    data['last_name']=lastName
                    data['mobile']=patientMobile
                    data['email']=patientEmail
                    data['location']=patientAddress
                    if(patientAge.length>0){
                        data['age']=patientAge
                    }                    
                }
                else if(basicId){
                    data['is_registered_patient']=true
                    data['fk_patient']=basicId
                }
                else{
                    Swal.fire({
                        text:"Invalid Entry Error",
                        icon:'error',
                        timer:1000,
                        showConfirmButton:false
                    })
                    return
                }

                if(location?.state?.id){
                    const response = await putPatientAppointment(location?.state?.id, data)
                    if(response?.success){
                        if(tempPatient){
                            navigate('/appointment-list')
                        }
                        else{
                            navigate(`/appointment-details`,{state:{
                                appointment_id:location?.state?.id
                            }
                            })
                        }
                    }
                }
                else{
                    const response = await postPatientAppointment(data)
                    if(response?.success){
                        var id = response?.data?.fk_appointment
                        if(tempPatient){
                            navigate('/appointment-list')
                        }
                        else{
                            navigate(`/appointment-details`,{state:{
                                appointment_id:id
                            }
                            })
                        }
                    }
                }
                
            }
        }
        catch(err){
            console.log(err)
        }
    }


    //<<<<<<<<<<<<<<<<<<<<<<<<<<< UseEffect Functions >>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>>
    
        
    useEffect(() => {
        physicianSpecialityList()
        if(!location?.state?.id){
            getAppointmentId()
        }        
    }, [])

    useEffect(()=>{
        if(location?.state?.id){
            getAppointmentDetails(location?.state?.id)
        }
    }, [location?.state])

    useEffect(() => {
        if(speciality){
            physicianListData()
        }
    }, [speciality])

    useEffect(()=>{
        if(appointmentDate){
            if(physician){
                shiftListData()
            }
        }
    },[appointmentDate])

    return (
        <div className="">
            <Tab.Container activeKey={"tab1"}>
                <div className='col-12'>
                    <Nav className='tabpane-container'>
                    <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab1'
                                onClick={()=>navigate('/appointment-add')}
                            >
                                <img
                                    src={physician_add}
                                    className='physician-add-icon'
                                    alt=''
                                />&nbsp;
                                Schedule
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab2'
                                onClick={()=>navigate('/appointment-details',{
                                    state:{
                                        appointment_id:location?.state?.id?location?.state?.id:null
                                    }
                                })}
                            >
                                <img
                                    src={physician_add}
                                    className='physician-add-icon'
                                    alt=''
                                />&nbsp;
                                Billing
                            </Nav.Link>
                        </Nav.Item>
                        <div className='d-flex justify-content-end col pe-3'>
                            <div
                                className='tabpane-extra-btns'
                                onClick={() => navigate('/appointment-list')}
                            >
                                <img
                                    src={patient_details}
                                    className='details-icon'
                                    alt=''
                                />&nbsp;&nbsp;
                                Appointment List
                            </div>
                        </div>
                    </Nav>
                </div>
                <div className='patient-list-container'>
                    <Tab.Content className='patient-table-container'>
                        <TabPane
                            eventKey='tab1'
                            className=''
                        >
                            <div className='row mx-0'>
                                <div className="col-8 page-title-label p-4">Appointment Details</div>
                                <div className="col-4 page-title-label p-4 row mx-0 d-flex align-items-center justify-content-end">
                                    <PatientSearchBox
                                        handleData={handleSearch}
                                        // icon={
                                        //     <div className="search-arrow-btn" >
                                        //         <span><MdKeyboardArrowRight size='30px' /></span>
                                        //     </div>
                                        // }
                                        customButton={true}
                                        buttonText={'Create Temporary Patient'}
                                        buttonClick={(data)=>{
                                            // console.log("hoooooooooooooi",data)
                                            if(isNaN(data)){
                                                setPatientName(data)
                                            }
                                            else{
                                                setPatientMobile(data)
                                            }
                                            setShowPatientCreateModal(true)
                                            setSearchClose(true)
                                        }}
                                        toggleControl={setSearchClose}
                                        toggleValue={searchClose}
                                    />
                                </div>
                                <div className="col-3 col-4 p-4">
                                    <div className='white-plain-container p-5 d-flex flex-column justify-content-center'>
                                        <div className="make-appointment-id py-1">{appointmentId&&appointmentId}</div>
                                        <div className="make-appointment-name py-1">{patientName&&patientName}</div>
                                    </div>
                                </div>
                                <div className="col-8 col-9 py-4 pe-4">
                                    <div className='white-plain-container row mx-0 px-4 d-flex align-items-center'>
                                        <div className="col-6 col-7">
                                            <div className="make-appointment-details pb-1">
                                                <div className="label">Admission Date</div>
                                                <div className="colon">:</div>
                                                <div className="data">{patientRegistered&&moment(patientRegistered).format('DD/MM/YYYY')}</div>
                                            </div>
                                            <div className="make-appointment-details py-2">
                                                <div className="label">Mob Number</div>
                                                <div className="colon">:</div>
                                                <div className="data">{patientMobile&&patientMobile}</div>
                                            </div>
                                            <div className="make-appointment-details pt-1">
                                                <div className="label">Email Address</div>
                                                <div className="colon">:</div>
                                                <div className="data">{patientEmail&&patientEmail}</div>
                                            </div>
                                        </div>
                                        <div className="col-5 col-6 d-flex flex-column justify-content-start">
                                            <div className="make-appointment-details pb-1">
                                                <div className="label">Patient Type</div>
                                                <div className="colon">:</div>
                                                <div className="data">{patientType&&patientType[0].toUpperCase()+patientType.slice(1,(patientType.length))}</div>
                                            </div>
                                            <div className="make-appointment-details align-items-start py-2">
                                                <div className="label">Address</div>
                                                <div className="colon">:</div>
                                                <div className="data">{patientAddress&&patientAddress}</div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="col-12 page-title-label ps-4 pt-3 pb-4">Doctor's Appointment</div>
                                <div className='left-split-container col-5 row px-4'>
                                    <DropDownBox
                                        label={'Speciality'}
                                        placeholder={'Select Speciality'}
                                        containerClass={'col-12 mb-3'}
                                        options={specialityList}
                                        setDataValue={setSpeciality}
                                        selectedValue={speciality}
                                        fluid={true}
                                        search={true}
                                        selection={true}

                                    />
                                    <DropDownBox
                                        label={'Doctor'}
                                        placeholder={'Select Doctor'}
                                        containerClass={'col-12 mb-3'}
                                        options={physicianList}
                                        selectedValue={physician}
                                        setDataValue={setPhysician}
                                        fluid={true}
                                        search={true}
                                        selection={true}
                                        disabled={speciality?false:true}
                                    />
                                    <DropDownBox
                                        label={'Shift'}
                                        placeholder={'Select Shift'}
                                        containerClass={'col-12 mb-3'}
                                        options={shiftList}
                                        setDataValue={setShift}
                                        selectedValue={shift}
                                        fluid={true}
                                        selection={true}
                                        disabled={physician?false:true}
                                    />
                                    <span className='py-5 my-1' />
                                </div>
                                <div className='col-7 row ms-2 ps-3 pe-0 me-0'>
                                    <Form.Group>
                                        <Form.Label
                                            className='label-text-content'
                                        >
                                            Appointment Date
                                        </Form.Label>
                                        <Calendar
                                            className='appointment-calender'
                                            minDate={new Date()}
                                            onChange={(e)=>setAppointmentDate(e)}
                                            value={appointmentDate&&appointmentDate}
                                        />
                                    </Form.Group>
                                </div>
                                <div className="col-12 row me-0 mt-3 pe-0">
                                    <span className='col-6 mt-4' />
                                    <div className="col-6 mt-4 row d-flex justify-content-end pe-0">
                                        <button
                                            className='form-previous-btn btn col-3 me-3'
                                            onClick={()=>navigate('/appointment-list')}
                                        >
                                            Cancel
                                        </button>
                                        <button
                                            className='form-next-btn btn col-3 me-0'
                                            onClick={handleFinalSubmit}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>
                            </div>
                        </TabPane>
                    </Tab.Content>
                    <Modal
                        show={showPatientCreateModal}
                        onHide={handleTempPatientCancel}
                        centered={true}
                        contentClassName="custom-modal"
                        size='lg'
                    >
                        <Modal.Header
                            closeButton
                            className='custom-modal-header'
                        >
                            Temperory Patient Details
                        </Modal.Header>
                        <Modal.Body
                            className='custom-modal-body'
                        >
                            <Form className='custom-modal-content row mx-0'>
                                <div
                                    className='col-12 row mx-0'
                                >
                                    <InputTextBox
                                        label={'First Name'}
                                        required={true}
                                        containerClass={'col-6 mb-3'}
                                        onChange={setPatientName}
                                        defvalue={patientName}
                                    />
                                    <InputTextBox
                                        label={'Last Name'}
                                        containerClass={'col-6 mb-3'}
                                        onChange={setLastName}
                                        defvalue={lastName}
                                    />
                                    <InputTextBox
                                        label={'Mobile'}
                                        required={true}
                                        containerClass={'col-6 mb-3'}
                                        onChange={setPatientMobile}
                                        defvalue={patientMobile}
                                    />
                                    <InputTextBox
                                        label={'Location'}
                                        required={true}
                                        containerClass={'col-6 mb-3'}
                                        onChange={setPatientAddress}
                                        defvalue={patientAddress}
                                    />
                                    <InputTextBox
                                        label={'Email'}
                                        containerClass={'col-9 mb-3'}
                                        onChange={setPatientEmail}
                                        defvalue={patientEmail}
                                    />
                                    <InputTextBox
                                        label={'Age'}
                                        type={'number'}
                                        containerClass={'col-3 mb-3'}
                                        onChange={setPatientAge}
                                        defvalue={patientAge}
                                    />
                                </div>
                                <div
                                    className='col-12 row my-3'
                                >
                                    <span className='col-8' />
                                    <div
                                        className='col-2'
                                    >
                                        <button 
                                            className='crop-cancel-btn'
                                            onClick={handleTempPatientCancel}
                                        >
                                            Cancel
                                        </button>
                                    </div>
                                    <div
                                        className='col-2'
                                    >
                                        <button 
                                            className='crop-submit-btn'
                                            onClick={handleTempPatientSubmit}
                                        >
                                            Submit
                                        </button>
                                    </div>
                                </div>  
                            </Form>
                        </Modal.Body>
                    </Modal>
                </div>
            </Tab.Container>
        </div>
    )
}

export default MakeAppointment
