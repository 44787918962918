import patient_details from '../../../../../assets/pictures/icons/patient-details-icon.png'
import passport_icon from '../../../../../assets/pictures/icons/passport-icon.png'
import medical_records from '../../../../../assets/pictures/icons/medical-records-icon.png'
import React, { useEffect, useState } from 'react'
import moment from 'moment'
import { Nav, Tab, TabPane } from 'react-bootstrap'
import './Registration.css'
import Swal from 'sweetalert2'
import BasicDetails from './components/TabPanes/BasicDetails'
import PassportAndVisa from './components/TabPanes/PassportAndVisa'
import MedicalRecords from './components/TabPanes/MedicalRecords'
import usePatientServices from '../../../../services/api-services/patientServices'
import { useLocation, useNavigate } from 'react-router-dom'
import useGetListDatas from '../../../../services/other-services/getListDatas'
import useAccountServices from '../../../../services/api-services/accountServices'
import useConsultationServices from '../../../../services/api-services/consultationServices'

const Registration = () => {

    function formatAMPM(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        hours = hours < 10 ? '0'+hours : hours; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes;
        return strTime;
    }

    function timeSet(time) {
        var hrs = time.slice(0, 2)
        var mns = time.slice(3, 5)
        var sec = time.slice(6, )
        var date = new Date().setHours(hrs)
        date = new Date(date).setMinutes(mns)
        date = new Date(date).setSeconds(sec)
        return new Date(date)
    }

    function defaultTime(date) {
        var hours = date.getHours();
        var minutes = date.getMinutes();
        var ampm = hours > 12 ? 'PM' : 'AM';
        hours = hours % 12
        hours = !hours? '12': hours < 10 ? '0'+hours : hours; // the hour '0' should be '12'
        minutes = minutes < 10 ? '0'+minutes : minutes;
        var strTime = hours + ':' + minutes + ' '+ ampm;
        return strTime;
    }

    //Patient Basic Detail Values
    const [mobileNumber, setMobileNumber] = useState('')
    const [firstName, setFirstName] = useState('')
    const [lastName, setLastName] = useState('')
    const [dOB, setDOB] = useState('')
    const [ageDate, setAgeDate] = useState('')
    const [ageMonth, setAgeMonth] = useState('')
    const [ageYear, setAgeYear] = useState('')
    const [gender, setGender] = useState('')
    const [bloodGroup, setBloodGroup] = useState('')
    const [maritalStatus, setMaritalStatus] = useState('')
    const [email, setEmail] = useState('')
    const [aadhar, setAadhar] = useState('')
    const [uIN, setUIN] = useState('')
    const [address1, setAddress1] = useState('')
    const [address2, setAddress2] = useState('')
    const [city, setCity] = useState('')
    const [pincode, setPincode] = useState('')
    const [district, setDistrict] = useState('')
    const [state, setState] = useState('')
    const [country, setCountry] = useState('India')
    const [patientId, setPatientId] = useState('')
    const [admDate, setAdmDate] = useState(new Date())
    const [admTime, setAdmTime] = useState(formatAMPM(new Date()))
    const [tempTime, setTempTime] = useState(defaultTime(new Date()))
    const [fileNum, setFileNum] = useState('')
    const [casualty, setCasualty] = useState('')
    const [patientType, setPatientType] = useState('native')
    const [refferedType, setRefferedType] = useState('NONE')
    const [emgContName, setEmgContName] = useState('')
    const [emgContNumber, setEmgContNumber] = useState('')
    const [emgContRelation, setEmgContRelation] = useState('')
    const [notes, setNotes] = useState('')

    //Patient Passport and Visa Values
    const [passportNumber, setPassportNumber] = useState('')
    const [passportExpiry, setPassportExpiry] = useState('')
    const [passport, setPassport] = useState(undefined)
    const [visaType, setVisaType] = useState('')
    const [visaValidity, setVisaValidity] = useState('')
    const [visaPurpose, setVisaPurpose] = useState('')
    const [visaRemarks, setVisaRemarks] = useState('')
    const [visa, setVisa] = useState(undefined)

    //Other Values
    const [key, setKey] = useState("tab1")
    const [basicId, setBasicId] = useState("")
    const [bloodGroupList, setBloodGroupList] = useState("")
    const [maritalStatusList, setMaritalStatusList] = useState("")

    // Medical records
    const [prevMedicalRecords, setPrevMedicalRecords] = useState([])
    const [currentMedicalRecords, setCurrentMedicalRecords] = useState([])
    const [treatmentHistory, setTreatmentHistory] = useState([])
    const [allergyList, setAllergyList] = useState([])
    const [patientDocuments, setPatientDocuments] = useState([])
    const [documentCategoryList, setDocumentCategoryList] = useState("")
    const [documentCategory, setDocumentCategory] = useState("")
    const [documentIssueDate, setDocumentIssueDate] = useState("")
    const [documentValidity, setDocumentValidity] = useState("")
    const [documentList, setDocumentList] = useState(undefined)
    const [patientHeight, setPatientHeight] = useState("")
    const [patientWeight, setPatientWeight] = useState("")
    const [bloodPressure, setBloodPressure] = useState("")
    const [pulse, setPulse] = useState("")
    const [respiration, setRespiration] = useState("")
    const [temperature, setTemperature] = useState("")

    const [documentFileId,setDocumentFileId] = useState("")
    const navigate = useNavigate()
    const location = useLocation()
    
    const { PatientEditData } = location.state || {}; // Destructure the state

    useEffect(() => {
        if (PatientEditData) {
            handleMobileSearch(PatientEditData);
        }
    }, [PatientEditData]);
    
    const {
        getNewID,
    } = useAccountServices()

    const {
        getPatientInfoChoices,
        getTempPatientDetails,
        getPatientDocumentCategories,
        getPatientMedicalIssues,
        getPatientTreatmentHistory,
        getPatientAllergicIssues,
        getPatientMedicalDocuments,
    } = usePatientServices()

    const {
        createPatientBasicsRegister,
        createPassportVisaDocuments,
        postPatientMedicalIssues,
        postPatientTreatmentHistory,
        postPatientAllergicIssues,
        postPatientDocumentCategories,
        postPatientMedicalDocuments,
        postPatientHealthRecord,
    } = usePatientServices()

   const { 
        putPatientDocument,
        deletePatientDocument,
    } = usePatientServices()

    const {
        postConvertAppointmentPatient
    } = useConsultationServices()

    const {
        deletePatientMedicalIssue,
        deletePatientTreatmentHistory,
        deletePatientAllergicIssues
    } = usePatientServices()

    const {
        getMultiInputList,
        getSemanticOptionsList
    } = useGetListDatas()

    const getTemporaryPatientDetails = async (patient_id) => {
        try {
            const response = await getTempPatientDetails(patient_id)
            if (response?.success) {
                const data = response?.data
                setFirstName(data?.first_name)
                setLastName(data?.last_name)
                setAgeYear(data?.age)
                setAddress1(data?.location)
                setMobileNumber(data?.mobile)
                setEmail(data?.email)
            }
        } catch { }
    }

    const getPatientId = async () => {
        try {
            const response = await getNewID('patient_id')
            if (response?.success) {
                setPatientId(response?.data?.complete_id)
            }
        } catch { }
    }

    const PatientInfoChoice = async () => {
        try {
            const response = await getPatientInfoChoices()
            if (response.success) {
                const blood = response.data.blood_groups
                const marriage = response.data.marital_statuses
                var tempList = []
                blood.map((item, index) => {
                    if (item === "other") {
                        tempList.push(
                            {
                                value: item,
                                text: 'Other'
                            }
                        )
                    }
                    else {
                        tempList.push(
                            {
                                value: item,
                                text: item
                            }
                        )
                    }
                })
                setBloodGroupList(tempList)
                tempList = []
                marriage.map((item, index) => {
                    const label = item[0].toUpperCase() + item.slice(-(item.length - 1))
                    tempList.push(
                        {
                            value: item,
                            text: label
                        }
                    )
                })
                setMaritalStatusList(tempList)
            }
        } catch { }
    }

    const patientDocumentCategories = async () => {
        try {
            const response = await getPatientDocumentCategories()
            if (response.success) {
                const data = response.data
                setDocumentCategoryList(getSemanticOptionsList(data, 'id', 'category'))
            }
        } catch { }
    }

    const getPrevMedIssues = async () => {
        const params = {
            present: 0
        }
        try {
            const response = await getPatientMedicalIssues(basicId, params)
            if (response?.success) {
                const issues = response?.data
                setPrevMedicalRecords(getMultiInputList(issues, 'id', 'issue'))
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const getCurrentMedIssues = async () => {
        const params = {
            present: 1
        }
        try {
            const response = await getPatientMedicalIssues(basicId, params)
            if (response?.success) {
                const issues = response?.data
                setCurrentMedicalRecords(getMultiInputList(issues, 'id', 'issue'))
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const addCurrentMedIssues = async (value) => {

        try {
            const data = JSON.stringify({
                issue: value,
                is_present_issue: true
            })
            const response = await postPatientMedicalIssues(basicId, data)
            if (response?.success) {
                getCurrentMedIssues()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }


    const addPrevMedIssues = async (value) => {

        try {
            const data = JSON.stringify({
                issue: value,
                is_present_issue: false
            })
            const response = await postPatientMedicalIssues(basicId, data)
            if (response?.success) {
                getPrevMedIssues()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const deleteMedIssue = async (issueId) => {

        try {
            const response = await deletePatientMedicalIssue(basicId, issueId)
            if (response?.success) {
                getCurrentMedIssues()
                getPrevMedIssues()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const getPatientTreatmentRecords = async () => {
        try {
            const response = await getPatientTreatmentHistory(basicId)
            if (response?.success) {
                const treatments = response?.data
                setTreatmentHistory(getMultiInputList(treatments, 'id', 'treatment'))
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const addTreatment = async (value) => {

        try {
            const data = JSON.stringify({
                treatment: value,
            })
            const response = await postPatientTreatmentHistory(basicId, data)
            if (response?.success) {
                getPatientTreatmentRecords()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const removeTreatment = async (treatmentId) => {

        try {
            const response = await deletePatientTreatmentHistory(basicId, treatmentId)
            if (response?.success) {
                getPatientTreatmentRecords()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const getPatientAllergicRecords = async () => {
        try {
            const response = await getPatientAllergicIssues(basicId)
            if (response?.success) {
                const issues = response?.data
                setAllergyList(getMultiInputList(issues, 'id', 'issue'))
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const addAllergy = async (value) => {

        try {
            const data = JSON.stringify({
                issue: value,
            })
            const response = await postPatientAllergicIssues(basicId, data)
            if (response?.success) {
                getPatientAllergicRecords()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const deleteAllergy = async (issueId) => {

        try {
            const response = await deletePatientAllergicIssues(basicId, issueId)
            if (response?.success) {
                getPatientAllergicRecords()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const getPatientDocuments = async () => {
        try {
            const response = await getPatientMedicalDocuments(basicId)
            if (response?.success) {
                setPatientDocuments(response?.data)
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const postPatientDocumentType = async (value) => {
        try {
            const data = JSON.stringify({
                category: value.value,
            })
            const response = await postPatientDocumentCategories(data)
            if (response?.success) {
                setDocumentCategory(response?.data?.id)
                patientDocumentCategories()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const handleDocumentUpload = async () => {
        //set Medical Document Data
        const medicalDocuments = new FormData()
        medicalDocuments.append("category", documentCategory)
        medicalDocuments.append("issued_date", documentIssueDate && moment(new Date(documentIssueDate)).format('YYYY-MM-DD'))
        medicalDocuments.append("validity_upto", documentValidity && moment(new Date(documentValidity)).format('YYYY-MM-DD'))
        documentList?.map((file, index) => {
            medicalDocuments.append("document" + (index + 1), file)
        })
        console.log("look",medicalDocuments)
        try {
            let response
            if (documentFileId !== ""||null){
                response = await putPatientDocument(basicId,documentFileId,medicalDocuments)
            }else{
                response = await postPatientMedicalDocuments(basicId, medicalDocuments)
            }
           if (response?.success) {
                setDocumentCategory("")
                setDocumentIssueDate("")
                setDocumentValidity("")
                setDocumentList(undefined)
                getPatientDocuments()
                setDocumentFileId("")
                Swal.fire("",response?.message,"success")
            }
            else{
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }
    // const handleUpdateDocument = async(doc_id) =>{
    //     const medicalDocuments = new FormData()
    //     medicalDocuments.append("category", documentCategory)
    //     medicalDocuments.append("issued_date", documentIssueDate && moment(new Date(documentIssueDate)).format('YYYY-MM-DD'))
    //     medicalDocuments.append("validity_upto", documentValidity && moment(new Date(documentValidity)).format('YYYY-MM-DD'))
    //     documentList?.map((file, index) => {
    //         medicalDocuments.append("document" + (index + 1), file)
    //     })
    //     try {
            
    //         if (response?.success) {
    //             setDocumentCategory("")
    //             setDocumentIssueDate("")
    //             setDocumentValidity("")
    //             setDocumentList(undefined)
    //             getPatientDocuments()
    //         }
    //         else{
    //             Swal.fire("", response?.message, "error")
    //         }
    //     } catch { }
    // }

    const handleDeleteDocument = async() =>{
        try {
            console.log(basicId,documentFileId)
            const response = await deletePatientDocument(basicId,documentFileId)
            console.log("Response Delete",response)
            if (response?.success) {
                setDocumentCategory("")
                setDocumentIssueDate("")
                setDocumentValidity("")
                setDocumentList(undefined)
                getPatientDocuments()
                setDocumentFileId("")
            }
            else{
                Swal.fire(
                {   title: 'Failed to delete',
                    text: '',
                    icon: 'error', // 'warning', 'success', 'info', or 'question'
                }
                )
            }
        } catch(e){
            console.log(e)
         }
    }

    const handleBasicDetailsSubmit = async () => {
        if (!mobileNumber) {
            Swal.fire('', 'Please Fill out this field: Mobile Number', 'info')
            console.log(mobileNumber)
            return
        }
        if (!firstName) {
            Swal.fire('', 'Please Fill out this field: First Name', 'info')
            console.log(firstName)
            return
        }
        if (!ageYear) {
            Swal.fire('', 'Please Fill out this field: Age Year', 'info')
            console.log(ageYear)
            return
        }
        if (!gender) {
            Swal.fire('', 'Please Fill out this field: Gender', 'info')
            console.log(gender)
            return
        }
        //set Data of Basic Details
        const basicDetails = new FormData()
        basicDetails.append("mobile", mobileNumber)
        basicDetails.append("first_name", firstName)
        basicDetails.append("last_name", lastName)
        basicDetails.append("dob", dOB && moment(new Date(dOB)).format('YYYY-MM-DD'))
        basicDetails.append("age_day", Number(ageDate))
        basicDetails.append("age_month", Number(ageMonth))
        basicDetails.append("age_year", Number(ageYear))
        basicDetails.append("gender", gender)
        basicDetails.append("blood_group", bloodGroup)
        basicDetails.append("marital_status", maritalStatus)
        basicDetails.append("email", email)
        basicDetails.append("UIN_type", aadhar)
        basicDetails.append("UIN", uIN)
        basicDetails.append("address_line_1", address1)
        basicDetails.append("address_line_2", address2)
        basicDetails.append("city", city)
        basicDetails.append("pincode", pincode)
        basicDetails.append("state", state)
        basicDetails.append("district", district)
        basicDetails.append("country", country)
        basicDetails.append("patient_id", patientId)
        basicDetails.append("admission_date", admDate && moment(new Date(admDate)).format('YYYY-MM-DD'))
        basicDetails.append("admission_tme", admTime)
        basicDetails.append("file_no", fileNum)
        basicDetails.append("casualty", casualty)
        basicDetails.append("patient_type", patientType)
        basicDetails.append("referred_type", refferedType)
        basicDetails.append("emergency_person_name", emgContName)
        basicDetails.append("emergency_contact", emgContNumber)
        basicDetails.append("relation_with_patient", emgContRelation)
        basicDetails.append("remark", notes)
        // console.log(basicDetails)
        try {
            const response = await createPatientBasicsRegister(basicDetails)
            if (response?.success) {
                setBasicId(response?.data?.id)
                handleTabChange()
            }
            else {
                Swal.fire("", response?.message, "error")
            }
            // console.log(response.data.id)
        } catch(err) {
            let data = err?.response?.data?.error
            let index = Object.keys(data)[0]
            let error = data[index][0]
            Swal.fire({
                title:index.toUpperCase(),
                text:error,
                icon:'error',
                timer:1000,
                showConfirmButton:false
            })
        }
    }

    const handleFinalSubmit = async () => {
        //set Data of passport and Visa Documents
        const passportAndVisa = new FormData()
        passportAndVisa.append("passport_number", passportNumber)
        passportAndVisa.append("date_of_expiry", passportExpiry && new Date(passportExpiry).toJSON())
        passportAndVisa.append("passport_file", passport)
        passportAndVisa.append("visa_type", visaType)
        passportAndVisa.append("validity_period", visaValidity && new Date(visaValidity).toJSON())
        passportAndVisa.append("visit_purpose", visaPurpose)
        passportAndVisa.append("remarks", visaRemarks)
        passportAndVisa.append("visa_file", visa)
        // console.log(passportAndVisa)

        //set Data of passport and Visa Documents
        const patientHealthData = new FormData()
        patientHealthData.append("height", patientHeight)
        patientHealthData.append("weight", patientWeight)
        patientHealthData.append("blood_pressure", bloodPressure)
        patientHealthData.append("pulse", pulse)
        patientHealthData.append("respiration", respiration)
        patientHealthData.append("temperature", temperature)
        // console.log(patientHealthData)
        if (patientType === 'foreigner') {
            try {
                const response = await createPassportVisaDocuments(basicId, passportAndVisa)
                if(response?.success){
                    setPassportNumber('')
                    setPassportExpiry('')
                    setPassport(undefined)
                    setVisaType('')
                    setVisaValidity('')
                    setVisaPurpose('')
                    setVisaRemarks('')
                    setVisa(undefined)
                }
                if (response?.success) {
                    Swal.fire("", response?.message, "error")
                }
            } catch { }
        }
        try {
            const response = await postPatientHealthRecord(basicId, patientHealthData)
            if (response?.success) {
                setPatientHeight("")
                setPatientWeight("")
                setBloodPressure("")
                setPulse("")
                setRespiration("")
                setTemperature("")

                setMobileNumber('')
                setFirstName('')
                setLastName('')
                setDOB('')
                setAgeDate('')
                setAgeMonth('')
                setAgeYear('')
                setGender('')
                setBloodGroup('')
                setMaritalStatus('')
                setEmail('')
                setAadhar('')
                setUIN('')
                setAddress1('')
                setAddress2('')
                setCity('')
                setPincode('')
                setState('')
                setDistrict('')
                setCountry('')
                setPatientId('')
                setAdmDate(new Date())
                setAdmTime(formatAMPM(new Date()))
                setFileNum('')
                setCasualty('')
                setRefferedType('NONE')
                setPatientType('native')
                setEmgContName('')
                setEmgContNumber('')
                setEmgContRelation('')
                setNotes('')

                Swal.fire("", "Patient Data Updated Successfully", "success")
                if(location?.state?.patient_id){
                    try{
                        const tempData = {
                            fk_patient:basicId,
                        }
                        const tempResponse = await postConvertAppointmentPatient(location?.state?.appointment_id, tempData)
                        if(tempResponse?.success){
                            navigate('/appointment-add', {
                                state:{
                                    id:location?.state?.appointment_id
                                }
                            })
                        }
                    }catch(err){console.log(err)}
                }
                else{
                    navigate(`/patient-card-generate`,{
                        state:{
                            patient_id:basicId
                        }
                    })
                }                
            }
            else {
                Swal.fire("", response?.message, "error")
            }
        } catch { }
    }

    const handleMobileSearch = (item) => {
        console.log(item)
        setBasicId(item?.id)
        setMobileNumber(item?.mobile)
        setFirstName(item?.first_name)
        setLastName(item?.last_name)
        setDOB(item?.dob?new Date(item?.dob):'')
        setAgeYear(item?.dob?'':item?.age_year&&item?.age_year)
        setGender(item?.gender)
        setBloodGroup(item?.blood_group)
        setMaritalStatus(item?.marital_status?item?.marital_status:'')
        setEmail(item?.email)
        setAadhar(item?.UIN_type)
        setUIN(item?.UIN)
        setAddress1(item?.address_line_1)
        setAddress2(item?.address_line_2)
        setCity(item?.city)
        setPincode(item?.pincode)
        setState(item?.state)
        setDistrict(item?.district)
        setCountry(item?.country)
        setPatientId(item?.patient_id)
        setAdmDate(item?.medical_record?.admission_date&&new Date(item.medical_record.admission_date))
        setAdmTime(item?.medical_record?.admission_tme&&formatAMPM(timeSet(item.medical_record.admission_tme)))
        setTempTime(item?.medical_record?.admission_tme&&defaultTime(timeSet(item.medical_record.admission_tme)))
        setFileNum(item?.medical_record?.file_no)
        setCasualty(item?.medical_record?.casualty)
        console.log(item?.medical_record?.casualty)
        setRefferedType(item?.medical_record?.referred_type)
        setPatientType(item?.patient_type)
        setEmgContName(item?.emergency_person_name)
        setEmgContNumber(item?.emergency_contact)
        setEmgContRelation(item?.relation_with_patient)
        setNotes(item?.medical_record?.remark)
    }

    const handleResetAll = () => {
        setBasicId('')
        setMobileNumber('')
        setFirstName('')
        setLastName('')
        setDOB('')
        setAgeYear('')
        setGender('')
        setBloodGroup('')
        setMaritalStatus('')
        setEmail('')
        setAadhar('')
        setUIN('')
        setAddress1('')
        setAddress2('')
        setCity('')
        setPincode('')
        setState('')
        setDistrict('')
        setCountry('')
        setPatientId('')
        setAdmDate(new Date())
        setAdmTime(formatAMPM(new Date()))
        setTempTime(defaultTime(new Date()))
        setFileNum('')
        setCasualty('')
        setRefferedType('NONE')
        setPatientType('native')
        setEmgContName('')
        setEmgContNumber('')
        setEmgContRelation('')
        setNotes('')
        //Patient Passport and Visa Values
        setPassportNumber('')
        setPassportExpiry('')
        setPassport(undefined)
        setVisaType('')
        setVisaValidity('')
        setVisaPurpose('')
        setVisaRemarks('')
        setVisa(undefined)
    
        //Other Values
        setKey("tab1")
        setBasicId("")
        setBloodGroupList("")
        setMaritalStatusList("")
    
        // Medical records
        setPrevMedicalRecords([])
        setCurrentMedicalRecords([])
        setTreatmentHistory([])
        setAllergyList([])
        setPatientDocuments([])
        setDocumentCategoryList("")
        setDocumentCategory("")
        setDocumentIssueDate("")
        setDocumentValidity("")
        setDocumentList(undefined)
        setPatientHeight("")
        setPatientWeight("")
        setBloodPressure("")
        setPulse("")
        setRespiration("")
        setTemperature("")
        getPatientId()
    }


    const handleTabChange = () => {
        switch (key) {
            case "tab1":
                if (patientType === 'native') {
                    setKey('tab3')
                }
                else {
                    setKey('tab2')
                }
                break
            case "tab2":
                setKey('tab3')
                break
            case "tab3":
                setKey('tab4')
                break
            default:
                setKey('tab1')
                break
        }

    }

    useEffect(()=>{
        if(location?.state?.patient_id){
            getTemporaryPatientDetails(location?.state?.patient_id)
        }
    },[location?.state?.patient_id])

    
    useEffect(() => {
        if (!PatientEditData) {
            getPatientId()
        }
        PatientInfoChoice()
        patientDocumentCategories()
    }, [])

    useEffect(() => {
        if (basicId) {
            getPrevMedIssues()
            getCurrentMedIssues()
            getPatientTreatmentRecords()
            getPatientAllergicRecords()
            getPatientDocuments()
        }
    }, [basicId])

    return (
        <div className=''>
            <Tab.Container activeKey={key} onSelect={(k) => setKey(k)}>
                <div className='col-12'>
                    <Nav className='tabpane-container'>
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab1'
                            >
                                <img
                                    src={patient_details}
                                    className='details-icon'
                                    alt=''
                                />&nbsp;
                                Patient Details
                            </Nav.Link>
                        </Nav.Item>
                        {patientType === 'foreigner' && <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab2'
                            >
                                <img
                                    src={passport_icon}
                                    className='passport-icon'
                                    alt=''
                                />&nbsp;
                                Passport & Visa
                            </Nav.Link>
                        </Nav.Item>}
                        <Nav.Item className='content'>
                            <Nav.Link
                                className='tabpane-item'
                                eventKey='tab3'
                            >
                                <img
                                    src={medical_records}
                                    className='medical-icon'
                                    alt=''
                                />&nbsp;&nbsp;
                                Medical Records
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>
                <div className='pagebody-container'>
                    <Tab.Content className='tabcontent-container'>
                        <TabPane
                            eventKey='tab1'
                            className=''
                        >
                            <BasicDetails
                                value={{
                                    ...{
                                        bloodGroupList,
                                        maritalStatusList,
                                        mobileNumber,
                                        firstName,
                                        lastName,
                                        dOB,
                                        ageDate,
                                        ageMonth,
                                        ageYear,
                                        gender,
                                        bloodGroup,
                                        maritalStatus,
                                        email,
                                        aadhar,
                                        uIN,
                                        address1,
                                        address2,
                                        city,
                                        pincode,
                                        state,
                                        district,
                                        country,
                                        admDate,
                                        admTime,
                                        tempTime,
                                        patientId,
                                        fileNum,
                                        casualty,
                                        patientType,
                                        refferedType,
                                        emgContName,
                                        emgContNumber,
                                        emgContRelation,
                                        notes,
                                    }
                                }}
                                setValue={{
                                    ...{
                                        setBloodGroup,
                                        setMaritalStatus,
                                        setCasualty,
                                        setPatientType,
                                        setGender,
                                        setMobileNumber,
                                        setFirstName,
                                        setLastName,
                                        setDOB,
                                        setAgeDate,
                                        setAgeMonth,
                                        setAgeYear,
                                        setEmail,
                                        setAadhar,
                                        setUIN,
                                        setAddress1,
                                        setAddress2,
                                        setCity,
                                        setPincode,
                                        setState,
                                        setDistrict,
                                        setCountry,
                                        setPatientId,
                                        setAdmDate,
                                        setAdmTime,
                                        setTempTime,
                                        setFileNum,
                                        setRefferedType,
                                        setEmgContName,
                                        setEmgContNumber,
                                        setEmgContRelation,
                                        setNotes,
                                    }
                                }}
                                functions={{
                                    ...{
                                        handleBasicDetailsSubmit,
                                        handleMobileSearch,
                                        handleResetAll,
                                    }
                                }}
                            />
                        </TabPane>
                        <TabPane
                            eventKey='tab2'
                            className=''
                        >
                            <PassportAndVisa
                                setKey={setKey}
                                value={{
                                    ...{
                                        passportNumber,
                                        passportExpiry,
                                        passport,
                                        visaType,
                                        visaValidity,
                                        visaPurpose,
                                        visaRemarks,
                                        visa
                                    }
                                }}
                                setValue={{
                                    ...{
                                        setPassportNumber,
                                        setPassportExpiry,
                                        setPassport,
                                        setVisaType,
                                        setVisaValidity,
                                        setVisaPurpose,
                                        setVisaRemarks,
                                        setVisa
                                    }
                                }}
                                functions={{
                                    ...{
                                        handleTabChange,
                                    }
                                }}
                            />
                        </TabPane>
                        <TabPane
                            eventKey='tab3'
                            className=''
                        >
                            <MedicalRecords
                                value={{
                                    ...{
                                        prevMedicalRecords,
                                        currentMedicalRecords,
                                        treatmentHistory,
                                        allergyList,
                                        documentCategoryList,
                                        documentCategory,
                                        documentIssueDate,
                                        documentValidity,
                                        documentList,
                                        patientDocuments,
                                        patientHeight,
                                        patientWeight,
                                        bloodPressure,
                                        pulse,
                                        respiration,
                                        temperature,
                                        documentFileId,
                                    }
                                }}
                                setValue={{
                                    ...{
                                        setPrevMedicalRecords,
                                        setCurrentMedicalRecords,
                                        setTreatmentHistory,
                                        setAllergyList,
                                        setDocumentCategory,
                                        setDocumentIssueDate,
                                        setDocumentValidity,
                                        setDocumentList,
                                        setPatientHeight,
                                        setPatientWeight,
                                        setBloodPressure,
                                        setPulse,
                                        setRespiration,
                                        setTemperature,
                                        setDocumentFileId
                                    }
                                }}
                                functions={{
                                    ...{
                                        addCurrentMedIssues,
                                        addPrevMedIssues,
                                        addTreatment,
                                        addAllergy,
                                        deleteMedIssue,
                                        removeTreatment,
                                        deleteAllergy,
                                        handleDocumentUpload,
                                        handleFinalSubmit,
                                        postPatientDocumentType,
                                        // handleUpdateDocument,
                                        handleDeleteDocument,
                                    }
                                }}
                                setKey={setKey}
                                patientType={patientType}
                            />
                        </TabPane>
                    </Tab.Content>
                </div>
            </Tab.Container >
        </div>
    )
}

export default Registration
