import React from 'react'

const PatientCountCard = ({title,count,img}) => {
  return (
    <div className='container-white d-flex align-items-center'>
        <div className="img-container d-flex justify-content-center align-items-center rounded">
            <img src={img} alt="..." />
        </div>
        <div className="container-text ps-3">
            <div className="text-main my-2">{title}</div>
            <div className="text-sub my-2">{count}</div>
        </div>
    </div>
  )
}

export default PatientCountCard