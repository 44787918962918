import React from 'react'

const AppointmentTable = (params) => {
    const {latestAppointmentData} = params
    // const appointments = [
    //     {
    //         id: '12345',
    //         patientName: 'Test Name',
    //         phoneNumber: '+91 98745 61000',
    //         token: 54,
    //         specification: 'Ortho',
    //         status: 'Draft',
    //     },
    //     {
    //         id: '12345',
    //         patientName: 'Test Name',
    //         phoneNumber: '+91 98745 61000',
    //         token: 54,
    //         specification: 'Ortho',
    //         status: 'Consulting',
    //     },
    //     {
    //         id: '12345',
    //         patientName: 'Test Name',
    //         phoneNumber: '+91 98745 61000',
    //         token: 54,
    //         specification: 'Ortho',
    //         status: 'Draft',
    //     },
    //     {
    //         id: '12345',
    //         patientName: 'Test Name',
    //         phoneNumber: '+91 98745 61000',
    //         token: 54,
    //         specification: 'Ortho',
    //         status: 'Waiting',
    //     },
        
       
    //     ];
  return (
    <div className="container-fluid">
      <table className="table tabg1 text-center">
        <thead>
          <tr>
            <th>Appt no</th>
            <th>Patient Name</th>
            <th>Phone Number</th>
            <th>Token</th>
            <th>Specification</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          {latestAppointmentData.map((d, index) => (
            <tr key={index}>
              <td>
                <a href="#" className="text-primary">
                  {d.appointment_id}
                </a>
              </td>
              <td>{d.patient_details.name}</td>
              <td>{d.patient_details.mobile}</td>
              <td className="text-primary">{d.token}</td>
              <td>{d.speciality}</td>
              <td>
                <div className={`bg-light border p-2 fw-bold rounded text-center`}>                    
                    {d.status.toUpperCase()}
                </div>
              </td>
            </tr>
          ))}
          {latestAppointmentData.length < 5 &&
          Array.from({ length: 5 - latestAppointmentData.length }).map((_, index) => (
            <tr key={`dummy-${index}`}>
              <td>
                <a href="#" className="text-primary">
                  Apt 00
                </a>
              </td>
              <td>Sample Name</td>
              <td>0000000000</td>
              <td className="text-primary">0000</td>
              <td>General Physician</td>
              <td>
                <div className={`bg-light border p-2 fw-bold rounded text-center`}>                    
                    {'Waiting'.toUpperCase()}
                </div>
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

export default AppointmentTable